import { useDisclosure } from "@chakra-ui/hooks";
import { Img } from "@chakra-ui/image";
import { Box, Flex, Text } from "@chakra-ui/layout";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/modal";
import moment from "moment";
import React, { useState } from "react";
import { ButtonBekeuLink } from "../../design-system/forms/ButtonBekeuLink";
import { H5 } from "../../design-system/headings/H5";
import notFoundImg from "../../design-system/images/not-found.png";

export const HistoryItemModal = ({ children, data, productsArr, ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [imageError, setImageError] = useState(false);
  
  return (
    <>
      <ButtonBekeuLink onClick={onOpen} lineHeight={"1.375rem"} {...props}>
        {children}
      </ButtonBekeuLink>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="sm">
        <ModalOverlay />
        <ModalContent borderRadius={"0.75rem"}>
          <ModalCloseButton _focus={{ boxShadow: "rgba(0,0,0,0)" }} />
          <ModalBody padding="3rem">
            <Flex flexDir={"column"} gap={"1rem"}>
              <H5>
                {data?.state_tag === "rejected"
                  ? "Detalle de cancelación"
                  : "Detalle del remito"}
              </H5>
              <Text color="brand.contentSecondary">
                Nro compra: {data?.buy_order.internal_code}
              </Text>
              <Text color="brand.contentSecondary">
                {data?.state_tag === "rejected"
                  ? "Nro de cancelación"
                  : "Nro de remito"}
                : {data?.dispatch_note_number}
              </Text>
              <Text color="brand.contentSecondary">
                Fecha: {moment(data?.updated_at).format("DD/MM/YYYY")}
              </Text>
              <Flex gap={2} alignItems="center">
                <Text color="brand.contentSecondary">Estado: </Text>
                <Box
                  color={
                    data.state === "1"
                      ? "brand.error"
                      : data.state === "2" ? "brand.warning"
                      : data.state === "3" && "brand.success"
                    }
                  background={
                    data.state === "1"
                      ? "brand.bgError"
                      : data.state === "2" ? "brand.bgWarning"
                      : data.state === "3" && "brand.bgSuccess"
                    }
                  padding={"0.5rem"}
                  px={"0.75rem"}
                  borderRadius={"0.75rem"}
                >
                  {data.state === "1"
                    ? "Cancelado por proveedor"
                    : data.state === "2" ? "Reclamado"
                    : data.state === "3" && "Confirmado"}
                </Box>
              </Flex>

              <Flex flexDir={"column"} gap="1.5rem" marginTop="1.5rem">
                {productsArr
                  ?.filter((product) => product.id === data.buy_order_item_id)
                  ?.map((product) => {
                    return (
                      <Flex gap="1.5rem" key={Math.random()}>
                        <Flex
                          w="4.375rem"
                          h="4.375rem"
                          border="1px solid #BDBDBD"
                          borderRadius={"0.75rem"}
                          overflow="hidden"
                          justifyContent={"center"}
                          alignItems="center"
                        >
                          <Img
                            objectFit="cover"
                            h={imageError ? "50%" : "100%"}
                            title={
                              imageError
                                ? "Imagen no disponible"
                                : product?.combination?.product?.name
                            }
                            draggable={false}
                            onError={() => setImageError(true)}
                            src={
                              imageError
                                ? notFoundImg
                                : product?.combination?.media?.image_source ||
                                  product?.combination?.media?.source
                            }
                          />
                        </Flex>
                        <Flex flexDir={"column"} justifyContent="space-between">
                          <Text lineHeight={"1.875rem"} noOfLines={2}>
                            {product?.combination?.product?.name}
                          </Text>
                          <Text fontWeight={600} color="brand.contentSecondary">
                            Cantidad: {data?.quantity_sent}
                          </Text>
                        </Flex>
                      </Flex>
                    );
                  })}
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
