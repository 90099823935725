import { useEffect } from "react";
import { Flex, useDisclosure, Grid, GridItem } from "@chakra-ui/react";
import { H5 } from "../../design-system/headings/H5";
import { AlertBekeu } from "../../design-system/overlay/AlertBekeu";
import { actions as CredentialsSAP } from "./ducks/CredentialsSAP.duck";
import { injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import CredentialsSAPForm from "../../components/CredentialsSAP/CredentialsSAPForm";
import CredentialsSAPSuccessModal from "../../components/CredentialsSAP/CredentialsSAPSuccessModal";

const CredentialsSAPPage = ({
  storeCredentialsSAP,
  getCredentialsSAP,
  testCredentialsSAP,
  setResult,
  setCredentialValid,
}) => {
  const CredentialsSAPState = useSelector((state) => state?.credentialsSAP);

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    return () => {
      setCredentialValid(null);
      setResult(null);
    };
  }, []);

  useEffect(() => {
    if (CredentialsSAPState?.result == true) {
      return onOpen();
    }
  }, [CredentialsSAPState?.result]);

  const handleSubmit = (payload) => {
    storeCredentialsSAP(payload);
  };

  const getCredentials = () => {
    getCredentialsSAP();
  };

  const handleTest = (payload) => {
    testCredentialsSAP(payload);
  };

  return (
    <Flex
      direction="column"
      justifyContent={["start", "start", "start", "center"]}
      paddingInline={4}
      alignItems={["start", "start", "start", "center"]}
      w={"100%"}
      mt={["4rem", "4rem", "2rem", "2rem"]}
    >
      <Grid
        templateColumns={["unset", "unset", "unset", "30% auto"]}
        alignItems="center"
        justifyContent="space-between"
        w={"82%"}
        ml={["2rem", "2rem", "3rem", "0"]}
      >
        <GridItem>
          <H5>Credenciales SAP</H5>
        </GridItem>

        <GridItem>
          <AlertBekeu my={"2rem"}>
            Ingresar credenciales y testear, si la conexión se realiza
            de manera exitosa podra enviar las credenciales.
          </AlertBekeu>
        </GridItem>
      </Grid>

      <Flex direction={"column"} alignItems={"center"} w={"94%"}>
        <CredentialsSAPForm
          onSubmit={handleSubmit}
          getCredentials={getCredentials}
          onTest={handleTest}
          isSubmitting={CredentialsSAPState?.loadingSend}
          isTesting={CredentialsSAPState?.loadingTest}
          credentialsValid={CredentialsSAPState?.credentialValid}
          setCredentialValid={setCredentialValid}
        />
      </Flex>

      <CredentialsSAPSuccessModal
        setResult={setResult}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Flex>
  );
};

export default injectIntl(
  connect(null, { ...CredentialsSAP })(CredentialsSAPPage)
);
