import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import {
  Flex,
  Img,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  UnorderedList,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import { H5 } from "../../design-system/headings/H5";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { Paragraphs } from "../../design-system/headings/Paragraphs";

import successsvg from "../../design-system/icons/Arrows/success-circle-fill.svg";
import errorsvg from "../../design-system/icons/Errors/error-sap.svg";

export const ApproveCartsSuccessModal = ({
  handleApproveCart,
  handlePagination,
  item,
  ...props
}) => {
  const cartsApproval = useSelector((state) => state.carts_approval);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [approvalLoading, setLoading] = useState(false);

  const toast = useToast();

  const handleApprove = () => {
    setLoading(true);
    handleApproveCart(item?.id);
    onOpen();
  };

  const handleOpen = () => {
    cartsApproval.approvalResult = null;
    cartsApproval.approvalResultValue = null;
    onOpen();
  };

  const handleClose = () => {
    setLoading(false);
    cartsApproval.approvalResult = null;
    cartsApproval.approvalResultValue = null;
    onClose();
  };

  useEffect(() => {
    setLoading({ approvalLoading: cartsApproval?.approvalLoading });
  }, [cartsApproval?.approvalLoading]);

  useEffect(() => {
    setLoading(cartsApproval?.approvalLoading);
    if (cartsApproval?.approvalResult) onOpen();
  }, [cartsApproval?.approvalResultValue]);

  useEffect(() => {
    if (cartsApproval?.approvalRejectResultFail?.status === true) {
      toast({
        title: "Ocurrio unError",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [cartsApproval?.approvalRejectResultFail]);

  return (
    <>
      <ButtonBekeu
        onClick={handleOpen}
        isLoading={approvalLoading && !cartsApproval?.approvalResult}
        {...props}
      >
        Aprobar compra
      </ButtonBekeu>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        closeOnEsc={cartsApproval && !cartsApproval?.approvalResult}
        closeOnOverlayClick={cartsApproval && !cartsApproval?.approvalResult}
        size={'xl'}
      >
        <ModalOverlay />
          <ModalContent borderRadius={'0.9375rem'} minW="33rem">
          <ModalHeader paddingY={7}>
            <ModalCloseButton _focus={{ boxShadow: "rgba(0,0,0,0)" }} />
          </ModalHeader>
          <ModalBody borderRadius={'0.9375rem'} p={'3rem'}>
            <Flex
              flexDir={"column"}
              justifyContent="center"
              alignItems="center"
              gap={6}
            >
              {cartsApproval?.approvalResultValue &&
                cartsApproval?.approvalResultValue?.status && (
                  <>

                    {cartsApproval?.approvalResultValue &&
                      cartsApproval?.approvalResultValue?.status ===
                        "success" && (
                        <>
                          <Img src={successsvg} />
                          <H5 textAlign="center">
                            ¡El carrito fue aprobado con éxito!
                          </H5>
                        </>
                      )}

                    {cartsApproval?.approvalResultValue &&
                      cartsApproval?.approvalResultValue?.status === "fail" && (
                        <>
                          <Img src={errorsvg} />
                          <H5 textAlign="center">No se aprobó el carrito</H5>
                        </>
                      )}

                    <Flex
                      flexDir={"column"}
                      justifyContent="center"
                      alignItems="center"
                      gap={6}
                      key="approveCartsSuccessModalResult"
                    >
                      <Flex flexDir={"column"}>
                        <Paragraphs
                          fontWeight={600}
                          fontSize={"1.125rem"}
                          color={
                            cartsApproval?.approvalResultValue?.status ===
                            "success"
                              ? "brand.success"
                              : "brand.error"
                          }
                        >
                          {" "}
                          Carrito Nº {item.internal_code}
                        </Paragraphs>
                        
                        {cartsApproval?.approvalResultValue &&
                          cartsApproval?.approvalResultValue?.status ===
                            "success" && (
                            <>
                              {cartsApproval.approvalResultValue?.message
                                ?.success &&
                                cartsApproval.approvalResultValue.message
                                  .success.length > 0 &&
                                cartsApproval.approvalResultValue.message.success.map(
                                  (successItem) =>
                                    Object.keys(successItem).map((success) => {
                                      const sapMsg =
                                        Array.isArray(successItem[success]) &&
                                        successItem[success].length > 0
                                          ? successItem[success][0]
                                          : success;
                                      const sapNumber = sapMsg?.replace(
                                        /^\D+/g,
                                        "",
                                      );
                                      return (
                                        <Flex
                                          key={Math.random()}
                                          flexDir={"column"}
                                        >
                                          <Paragraphs
                                            fontWeight={600}
                                            fontSize={"1.125rem"}
                                            color="brand.success"
                                          >
                                            Compra Nº {success}
                                          </Paragraphs>
                                          <Paragraphs lineHeight="1.50rem">
                                            {sapNumber?.length > 0 &&
                                              `Pedido Bekeu creado bajo el número ${sapNumber}`}
                                          </Paragraphs>
                                        </Flex>
                                      );
                                    }),
                                )}

                              {/* {cartsApproval.approvalResultValue?.message
                                .error &&
                                cartsApproval.approvalResultValue.message.error
                                  .length > 0 &&
                                cartsApproval.approvalResultValue.message.error.map(
                                  (errorsItem) =>
                                    Object.keys(errorsItem).map((error) => {
                                      const sapMsg =
                                        Array.isArray(errorsItem[error]) &&
                                        errorsItem[error].length > 0
                                          ? errorsItem[error][0]
                                          : error;
                                      return (
                                        <Flex flexDir={"column"}>
                                          <Paragraphs
                                            fontWeight={600}
                                            fontSize={"1.125rem"}
                                            color="brand.error"
                                          >
                                            Compra Nº {error} <br />
                                          </Paragraphs>
                                          <br />
                                          <Paragraphs lineHeight="1.50rem">
                                            La compra no pudo realizarse por los
                                            siguientes motivos:
                                          </Paragraphs>
                                          <br />
                                          <Paragraphs lineHeight="1.50rem">
                                            <UnorderedList>
                                              {Array.isArray(
                                                errorsItem[error],
                                              ) &&
                                              errorsItem[error].length > 0 ? (
                                                errorsItem[error].map(
                                                  (item, id) => (
                                                    <ListItem key={id}>
                                                      {item}
                                                    </ListItem>
                                                  ),
                                                )
                                              ) : (
                                                <ListItem>{sapMsg}</ListItem>
                                              )}
                                            </UnorderedList>
                                          </Paragraphs>
                                        </Flex>
                                      );
                                    }),
                                )} */}
                            </>
                          )}
                          
                        {cartsApproval?.approvalResultValue &&
                          cartsApproval?.approvalResultValue?.status ===
                            "fail" && (
                            <>
                              {/* {cartsApproval.approvalResultValue?.error
                                ?.success &&
                                cartsApproval.approvalResultValue.error.success
                                  .length > 0 &&
                                cartsApproval.approvalResultValue.error.success?.map(
                                  (successItem) =>
                                    Object.keys(successItem).map((success) => {
                                      const sapMsg =
                                        Array.isArray(successItem[success]) &&
                                        successItem[success].length > 0
                                          ? successItem[success][0]
                                          : success;
                                      const sapNumber = sapMsg?.replace(
                                        /^\D+/g,
                                        "",
                                      );
                                      return (
                                        <Flex flexDir={"column"}>
                                          <Paragraphs
                                            fontWeight={600}
                                            fontSize={"1.125rem"}
                                            color="brand.success"
                                          >
                                            Compra Nº {success}
                                          </Paragraphs>
                                          <Paragraphs lineHeight="1.50rem">
                                            {sapNumber?.length > 0 &&
                                              `Pedido Bekeu creado bajo el número ${sapNumber}`}
                                          </Paragraphs>
                                        </Flex>
                                      );
                                    }),
                                )} */}
                              {cartsApproval.approvalResultValue?.error
                                ?.error &&
                                cartsApproval.approvalResultValue.error.error
                                  .length > 0 &&
                                cartsApproval.approvalResultValue.error.error?.map(
                                  (errorsItem) =>
                                    Object.keys(errorsItem).map((error) => {
                                      const sapMsg =
                                        Array.isArray(errorsItem[error]) &&
                                        errorsItem[error].length > 0
                                          ? errorsItem[error][0]
                                          : error;
                                      return (
                                        <Flex flexDir={"column"}>
                                          <Paragraphs
                                            fontWeight={600}
                                            fontSize={"1.125rem"}
                                            color="brand.error"
                                          >
                                            Compra Nº {error} <br />
                                          </Paragraphs>
                                          <br />
                                          <Paragraphs lineHeight="1.50rem">
                                            La compra no pudo realizarse por los
                                            siguientes motivos:
                                          </Paragraphs>
                                          <br />
                                          <Paragraphs lineHeight="1.50rem">
                                            <UnorderedList>
                                              {Array.isArray(
                                                errorsItem[error],
                                              ) &&
                                              errorsItem[error].length > 0 ? (
                                                errorsItem[error].map(
                                                  (item, id) => (
                                                    <ListItem key={id}>
                                                      {item}
                                                    </ListItem>
                                                  ),
                                                )
                                              ) : (
                                                <ListItem>{sapMsg}</ListItem>
                                              )}
                                            </UnorderedList>
                                          </Paragraphs>
                                        </Flex>
                                      );
                                    }),
                                )}
                            </>
                          )}                                         
                      </Flex>
  
                      <Flex fontSize="0.9125rem" justifyContent={"center"}>
                        <ButtonBekeu
                          m={0}
                          onClick={() => {
                            handleClose();
                            handlePagination();
                          }}
                        >
                          Aceptar
                        </ButtonBekeu>
                      </Flex>
                    </Flex>
                  </>
                )}
              {!cartsApproval?.approvalResult &&
                !cartsApproval?.approvalResultValue && (
                  <Flex flexDir={"column"} justifyContent="center" gap={6}>
                    <H5 textAlign="center" paddingInline={"1rem"}>
                      ¿Estás seguro que querés aprobar la compra?
                    </H5>
                    <Flex alignItems="center" gap={5}>
                      <ButtonBekeuOutline onClick={onClose} m={0}>
                        No
                      </ButtonBekeuOutline>
                      <ButtonBekeu
                        onClick={handleApprove}
                        isLoading={
                          approvalLoading && !cartsApproval?.approvalResult
                        }
                        m={0}
                      >
                        Si
                      </ButtonBekeu>
                    </Flex>
                  </Flex>
                )}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
