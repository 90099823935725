import { useEffect, useState } from "react";
import { Flex, Text, useToast } from "@chakra-ui/react";
import { H5 } from "../../design-system/headings/H5";
import { BoxBekeu } from "../../design-system/layout/BoxBekeu";
import { InputBekeu } from "../../design-system/inputs/InputBekeu";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import { useSelector } from "react-redux";

const CredentialsSAPForm = ({
  onSubmit,
  onTest,
  getCredentials,
  isSubmitting,
  isTesting,
  credentialsValid,
  setCredentialValid,
}) => {
  const CredentialsSAPState = useSelector((state) => state?.credentialsSAP);

  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [url, setUrl] = useState("");
  const [tokenUrl, setTokenUrl] = useState("");
  const [bapiPOCreate1, setBapiPOCreate1] = useState("");
  const [bapiGoodsmvtCreate, setBapiGoodsmvtCreate] = useState("");
  const [bapiPOChange, setBapiPOChange] = useState("");
  const [zmmfm0001, setZmmfm0001] = useState("");
  const [zmmfm0005, setZmmfm0005] = useState("");

  const toast = useToast();

  const handleClientIdOnChange = (value) => {
    setCredentialValid(null);
    setClientId(value);
  };

  const handleClientSecretOnChange = (value) => {
    setCredentialValid(null);
    setClientSecret(value);
  };

  const handleUrlOnChange = (value) => {
    setCredentialValid(null);
    setUrl(value);
  };

  const handleTokenUrlOnChange = (value) => {
    setCredentialValid(null);
    setTokenUrl(value);
  };

  const handleBapiPOCreate1OnChange = (value) => {
    setCredentialValid(null);
    setBapiPOCreate1(value);
  };

  const handleBapiGoodsmvtCreateOnChange = (value) => {
    setCredentialValid(null);
    setBapiGoodsmvtCreate(value);
  };

  const handleBapiPOChangeOnChange = (value) => {
    setCredentialValid(null);
    setBapiPOChange(value);
  };

  const handleZmmfm0001OnChange = (value) => {
    setCredentialValid(null);
    setZmmfm0001(value);
  };

  const handleZmmfm0005OnChange = (value) => {
    setCredentialValid(null);
    setZmmfm0005(value);
  };

  const handleSubmit = async () => {
    const payload = {
      clientId,
      clientSecret,
      url,
      tokenUrl,
      bapiPOCreate1,
      bapiGoodsmvtCreate,
      bapiPOChange,
      zmmfm0001,
      zmmfm0005,
    };
    await onSubmit(payload);
  };

  const handleTest = async () => {
    const payload = {
      clientId,
      clientSecret,
      url,
      tokenUrl,
      bapiPOCreate1,
      bapiGoodsmvtCreate,
      bapiPOChange,
      zmmfm0001,
      zmmfm0005,
    };
    await onTest(payload);
  };

  const getCredentialsSAP = async () => {
    await getCredentials();
  };

  useEffect(() => {
    getCredentialsSAP();
  }, []);

  useEffect(() => {
    if (CredentialsSAPState?.result) {
      setClientId(CredentialsSAPState?.result?.client_id);
      setUrl(CredentialsSAPState?.result?.url);
      setTokenUrl(CredentialsSAPState?.result?.token_url);
      setBapiPOCreate1(CredentialsSAPState?.result?.bapi_po_create1);
      setBapiGoodsmvtCreate(CredentialsSAPState?.result?.bapi_goodsmvt_create);
      setBapiPOChange(CredentialsSAPState?.result?.bapi_po_change);
      setZmmfm0001(CredentialsSAPState?.result?.zmmfm0001);
      setZmmfm0005(CredentialsSAPState?.result?.zmmfm0005);
    }
  }, [CredentialsSAPState?.result]);

  const isFormValid = () => {
    return (
      clientId &&
      clientSecret &&
      url &&
      tokenUrl &&
      bapiPOCreate1 &&
      bapiGoodsmvtCreate &&
      bapiPOChange &&
      zmmfm0001 &&
      zmmfm0005
    );
  };

  useEffect(() => {
    if (CredentialsSAPState?.credentialValid == true) {
      toast({
        title: "Conexión exitosa",
        description: "Las credenciales son correctas",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } else if (CredentialsSAPState?.credentialValid == false) {
      toast({
        title: "Conexión fallida",
        description: "Las credenciales son incorrectas",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [CredentialsSAPState?.credentialValid]);

  return (
    <Flex
      direction="column"
      justifyContent="center"
      paddingInline={4}
      pb="3rem"
      w="90%"
    >
      <BoxBekeu
        p={0}
        overflow="unset"
        padding="1.5rem"
        w="100%"
        backgroundColor="#FCFCFC"
      >
        <Flex justifyContent={"space-between"}>
          <H5 mb="1.5rem" color={"#111111"}>
            Credenciales 0Auth
          </H5>
        </Flex>
        <Flex flexDir="column" justifyContent="space-between" mb="1.5rem">
          <Flex
            direction="column"
            justifyContent="space-between"
            mb=".5rem"
            w={"100%"}
          >
            <Text fontSize="1rem" fontWeight="300" mb=".5rem" color={"#000000"}>
              Client ID
            </Text>
            <InputBekeu
              height="3rem"
              w={"100%"}
              isRequired
              maxLength={200}
              value={clientId}
              onChange={(e) => handleClientIdOnChange(e.target.value)}
            />
          </Flex>
          <Flex direction="column" justifyContent="space-between" w={"100%"}>
            <Text fontSize="1rem" fontWeight="300" mb=".5rem" color={"#000000"}>
              Client Secret
            </Text>
            <InputBekeu
              height="3rem"
              w={"100%"}
              isRequired
              maxLength={200}
              value={clientSecret}
              onChange={(e) => handleClientSecretOnChange(e.target.value)}
              type="password"
            />
          </Flex>
          <Flex direction="column" justifyContent="space-between" w={"100%"}>
            <Text fontSize="1rem" fontWeight="300" mb=".5rem" color={"#000000"}>
              URL
            </Text>
            <InputBekeu
              height="3rem"
              w={"100%"}
              isRequired
              maxLength={200}
              value={url}
              onChange={(e) => handleUrlOnChange(e.target.value)}
            />
          </Flex>
          <Flex direction="column" justifyContent="space-between" w={"100%"}>
            <Text fontSize="1rem" fontWeight="300" mb=".5rem" color={"#000000"}>
              Token URL
            </Text>
            <InputBekeu
              height="3rem"
              w={"100%"}
              isRequired
              maxLength={200}
              value={tokenUrl}
              onChange={(e) => handleTokenUrlOnChange(e.target.value)}
            />
          </Flex>
        </Flex>

        <Flex justifyContent={"space-between"}>
          <H5 mb="1.5rem" color={"#111111"}>
            Endpoints BAPI
          </H5>
        </Flex>
        <Flex flexDir="column" justifyContent="space-between" mb="1.5rem">
          <Flex
            direction="column"
            justifyContent="space-between"
            mb=".5rem"
            w={"100%"}
          >
            <Text fontSize="1rem" fontWeight="300" mb=".5rem" color={"#000000"}>
              BAPI_PO_CREATE1
            </Text>
            <InputBekeu
              height="3rem"
              w={"100%"}
              isRequired
              maxLength={200}
              value={bapiPOCreate1}
              onChange={(e) => handleBapiPOCreate1OnChange(e.target.value)}
            />
          </Flex>
          <Flex direction="column" justifyContent="space-between" w={"100%"}>
            <Text fontSize="1rem" fontWeight="300" mb=".5rem" color={"#000000"}>
              BAPI_GOODSMVT_CREATE
            </Text>
            <InputBekeu
              height="3rem"
              w={"100%"}
              isRequired
              maxLength={200}
              value={bapiGoodsmvtCreate}
              onChange={(e) => handleBapiGoodsmvtCreateOnChange(e.target.value)}
            />
          </Flex>
          <Flex direction="column" justifyContent="space-between" w={"100%"}>
            <Text fontSize="1rem" fontWeight="300" mb=".5rem" color={"#000000"}>
              BAPI_PO_CHANGE
            </Text>
            <InputBekeu
              height="3rem"
              w={"100%"}
              isRequired
              maxLength={200}
              value={bapiPOChange}
              onChange={(e) => handleBapiPOChangeOnChange(e.target.value)}
            />
          </Flex>
          <Flex direction="column" justifyContent="space-between" w={"100%"}>
            <Text fontSize="1rem" fontWeight="300" mb=".5rem" color={"#000000"}>
              ZMMFM0001
            </Text>
            <InputBekeu
              height="3rem"
              w={"100%"}
              isRequired
              maxLength={200}
              value={zmmfm0001}
              onChange={(e) => handleZmmfm0001OnChange(e.target.value)}
            />
          </Flex>
          <Flex direction="column" justifyContent="space-between" w={"100%"}>
            <Text fontSize="1rem" fontWeight="300" mb=".5rem" color={"#000000"}>
              ZMMFM0005
            </Text>
            <InputBekeu
              height="3rem"
              w={"100%"}
              isRequired
              maxLength={200}
              value={zmmfm0005}
              onChange={(e) => handleZmmfm0005OnChange(e.target.value)}
            />
          </Flex>
        </Flex>

        <Flex
          flexDir={["column", "column", "row", "row"]}
          justifyContent={"center"}
          mt="0.75rem"
          mb="0.75rem"
        >
          <ButtonBekeu
            isDisabled={!isFormValid() || isTesting || isSubmitting}
            p="0"
            mr={"1rem"}
            w={["100%", "100%", "22rem", "22rem"]}
            height="3rem"
            onClick={handleTest}
            isLoading={isTesting}
          >
            Testear
          </ButtonBekeu>
          <ButtonBekeu
            isDisabled={
              !isFormValid() || isSubmitting || isTesting || !credentialsValid
            }
            p="0"
            w={["100%", "100%", "22rem", "22rem"]}
            height="3rem"
            onClick={handleSubmit}
            isLoading={isSubmitting}
          >
            Enviar
          </ButtonBekeu>
        </Flex>
      </BoxBekeu>
    </Flex>
  );
};

export default CredentialsSAPForm;
