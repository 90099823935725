import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/es-mx";

import {
  Box,
  Collapse,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Img,
  Spinner,
  Text,
} from "@chakra-ui/react";

import { H6 } from "../../design-system/headings/H6";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { BoxBekeu } from "../../design-system/layout/BoxBekeu";
import { ButtonBekeuLink } from "../../design-system/forms/ButtonBekeuLink";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import { InputBekeu } from "../../design-system/inputs/InputBekeu";

import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import notFoundImg from "../../design-system/images/not-found.png";
import eye from "../../design-system/icons/Security/eye.svg";
import arrowDown from "../../design-system/icons/Arrows/arrow-down.svg";
import downloadArroy from "../../design-system/icons/Arrows/download-arrow.svg";
import warning from "../../design-system/icons/Shapes/warning-2.svg";

import { SalesRejectModal } from "../SalesRejectModal/SalesRejectModal";
import { SalesSuccessModal } from "../SalesSuccessModal/SalesSuccessModal";
import { SalesBadge } from "../SalesBadge/SalesBadge";
import { MaxDeliveryTimeBadge } from "../MaxDeliveryTimeBadge/MaxDeliveryTimeBadge";
import { ShippingCostBadge } from "../ShippingCostBadge/ShippingCostBadge";
import { HistoryItem } from "../HistoryItem/HistoryItem";
import { WarningBekeu } from "../../design-system/overlay/WarningBekeu";

import ModalPersonalInfo from "../../components/Common/ModalPersonalInfo";
import SalesSummary from "./pro/SalesSummary";

import * as product from "../../pages/productGridPage/ducks/productsGrid.duck";
import useDownloadBuyOrder from "../../hooks/useDownloadBuyOrder";

import useFormatCurrency from "../../hooks/useFormatCurrency";
import { PRO_TENANTS } from "../../constants/constants";
import useFeatures from "../../hooks/useFeatures";

const DetailItem = ({
  item,
  order,
  itemsState,
  getHistory,
  orderItem,
  newDeliveryArray,
  tenant,
  handleSetHistoryToCollapse,
  historyToCollapse,
}) => {
  const salesState = useSelector((state) => state?.sales);
  const [quantityInput, setQuantityInput] = useState(
    item?.quantity - item?.quantity_sent,
  );
  const [isOpen, setIsOpen] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [totalQuantityValidation, setQuantityValidation] = useState(false);

  useEffect(() => {
    setQuantityInput(item?.quantity - item?.quantity_sent);
    setQuantityValidation(
      item?.quantity === item?.quantity_sent + item?.quantity_cancel,
    );
  }, [item, totalQuantityValidation]);

  const handleHistoryClick = (id) => {
    const newOpen = [...historyToCollapse];
    if (!newOpen[id]) {
      newOpen.fill(false);
      newOpen[id] = true;
    } else {
      newOpen.fill(false);
    }
    handleSetHistoryToCollapse(newOpen);

    !historyToCollapse[id] &&
      getHistory({ order_id: orderItem.id, tenant: tenant });
  };

  return (
    <>
      <Flex flexDir={"column"}>
        <Flex p="1.5rem" overflowX={"auto"}>
          <Flex
            w="5.5rem"
            h="5.5rem"
            mr="1.25rem"
            border="0.06rem solid #BDBDBD"
            borderRadius={"0.75rem"}
            overflow="hidden"
            justifyContent={"center"}
            alignItems="center"
          >
            <Img
              src={
                imageError
                  ? notFoundImg
                  : item?.combination?.media?.image_source ||
                    item?.data_combination?.image_source
              }
              onError={() => setImageError(true)}
              draggable={"false"}
              objectFit="cover"
              title={
                imageError
                  ? "Imagen no disponible"
                  : item?.combination?.title ||
                    item?.data_combination?.comb_title
              }
              h={imageError ? "50%" : "100%"}
            />
          </Flex>
          <Flex justifyContent={"space-between"} width={"100%"} gap="0.75rem">
            <Flex
              flexDir={"column"}
              justifyContent="space-between"
              w={"24.375rem"}
              gap="0.75rem"
            >
              <H6
                noOfLines={2}
                title={
                  item?.combination?.title || item?.data_combination?.comb_title
                }
              >
                {item?.combination?.title || item?.data_combination?.comb_title}
              </H6>
              <Paragraphs
                lineHeight="1.37rem"
                overflow="hidden"
                noOfLines={2}
                color="brand.contentSecondary"
              >
                {item?.combination?.long_description ||
                  item?.data_combination?.comb_long_description}
              </Paragraphs>
              <Paragraphs lineHeight="1.37rem" color="brand.contentSecondary">
                SKU: {item?.combination?.sku || item?.data_combination?.sku}
              </Paragraphs>
            </Flex>
            <Flex
              flexDir={"column"}
              gap="0.75rem"
              h={"3.75rem"}
              fontWeight={600}
              lineHeight="1.37rem"
              fontSize={"1rem"}
            >
              <Text>Precio del producto</Text>
              <Text>${item?.price_unit} + Imp</Text>
            </Flex>
            <Flex
              flexDir={"column"}
              gap="0.75rem"
              h={"3.75rem"}
              fontWeight={600}
              lineHeight="1.37rem"
              fontSize={"1rem"}
            >
              <Text color={"brand.contentPrimary"} fontWeight={600}>
                Cantidad comprada
              </Text>
              <Text color={"brand.contentPrimary"}>{item?.quantity}</Text>
            </Flex>
            <Flex
              flexDir={"column"}
              gap="0.75rem"
              h={"3.75rem"}
              fontWeight={600}
              lineHeight="1.37rem"
              fontSize={"1rem"}
            >
              <Text color={"brand.contentPrimary"} fontWeight={600}>
                Cantidad ya enviada
              </Text>
              <Text color={"brand.contentPrimary"}>{item?.quantity_sent}</Text>
            </Flex>
            <Flex
              flexDir={"column"}
              gap="0.75rem"
              h={"3.75rem"}
              fontWeight={600}
              lineHeight="1.37rem"
              fontSize={"1rem"}
            >
              <Text color={"brand.contentPrimary"} fontWeight={600}>
                Cantidad cancelada
              </Text>
              <Text color={"brand.contentPrimary"}>
                {item?.quantity_cancel}
              </Text>
            </Flex>
            <Flex
              flexDir={"column"}
              gap="0.75rem"
              h={"3.75rem"}
              fontWeight={600}
              lineHeight="1.37rem"
              fontSize={"1rem"}
            >
              <Text color={"brand.contentPrimary"} fontWeight={600}>
                Cantidad confirmada
              </Text>
              <Text color={"brand.contentPrimary"}>
                {item?.quantity_confirmed}
              </Text>
            </Flex>
            {!totalQuantityValidation && (
              <Flex
                flexDir={"column"}
                gap="0.75rem"
                h={"3.75rem"}
                fontWeight={600}
                lineHeight="1.37rem"
                fontSize={"1rem"}
              >
                <Text>
                  Cantidad en
                  <br />
                  este envío
                </Text>
                <InputBekeu
                  borderRadius="0.75rem"
                  height="3rem"
                  width="8.5rem"
                  disabled={order?.state === "3" || order?.state === "4"}
                  //defaultValue={item?.quantity - item?.quantity_sent}
                  placeholder={item?.quantity - item?.quantity_sent}
                  value={quantityInput}
                  onChange={(e) => {
                    if (
                      e.target.value <=
                      item?.quantity - item?.quantity_sent
                    ) {
                      setQuantityInput(
                        Number(e.target.value.replace(/[^0-9,.]/g, "")),
                      );
                      itemsState.filter(
                        (a) => a.buy_order_item_id === item.id,
                      )[0].qty_sent = Number(
                        e.target.value.replace(/[^0-9,.]/g, ""),
                      );
                    }
                  }}
                  max={item?.quantity - item?.quantity_sent}
                  borderColor="rgba(226, 226, 226, 1)"
                  color="#000000"
                />
              </Flex>
            )}
          </Flex>
        </Flex>
        <Flex justifyContent={"flex-start"}>
          <ButtonBekeuLink
            onClick={() => {
              handleHistoryClick(item?.collapseIndex);
            }}
            mr="1.5rem"
            p={"0 1.5rem 0.5rem 1.5rem"}
            rightIcon={
              <Img
                style={{
                  transform: `rotate(${historyToCollapse[item?.collapseIndex] ? "180deg" : "0deg"})`,
                }}
                src={arrowDown}
              />
            }
            w={"100%"}
            justifyContent={"space-between"}
          >
            Actividad del pedido
          </ButtonBekeuLink>
        </Flex>
        <Collapse
          in={historyToCollapse[item?.collapseIndex]}
          animateOpacity
          style={{
            overflow: `${isOpen ? "unset" : "hidden"}`,
            zIndex: 999,
          }}
        >
          <Divider mb={"1.5rem"} />

          {salesState?.historyLoading ? (
            <Flex justifyContent={"center"} alignItems="center">
              <Spinner mb="2.75rem" />
            </Flex>
          ) : (
            <div
              style={{
                marginBottom: "2.75rem",
                padding: "1.5rem",
              }}
            >
              {salesState?.history?.length > 0 ? (
                salesState?.history.filter(
                  (filterItem) =>
                    filterItem?.dispatch_note?.buy_order_item_id === item.id,
                ).length > 0 ? (
                  salesState?.history
                    .filter(
                      (filterItem) =>
                        filterItem?.dispatch_note?.buy_order_item_id ===
                        item.id,
                    )
                    .map((note, id) => (
                      <HistoryItem
                        msg={note}
                        key={id}
                        product={item}
                        orderItem={orderItem}
                        newDeliveryArray={newDeliveryArray}
                        tenant={tenant}
                      />
                    ))
                ) : (
                  <WarningBekeu
                    fontSize={"1rem"}
                    icon={warning}
                    whidth={"100%"}
                    boxShadow="lg"
                  >
                    Este pedido aún no tiene movimientos.
                  </WarningBekeu>
                )
              ) : (
                <WarningBekeu
                  fontSize={"1rem"}
                  icon={warning}
                  whidth={"100%"}
                  boxShadow="lg"
                >
                  Este pedido aún no tiene movimientos.
                </WarningBekeu>
              )}
            </div>
          )}
        </Collapse>

        <Divider />
      </Flex>
    </>
  );
};

const SalesItem = ({
  item,
  type,
  showitems,
  selected,
  state,
  getOneProduct,
  handleApproveOrder,
  handleRejectOrder,
  setRemitNumber,
  sellerComments,
  setSellerComments,
  handlePagination,
  remitNumber,
  params,
  itemArr,
  handleFillResult,
  getHistory,
  tenant,
  msgFileNameInput,
  fileNameInput,
  handleChangeFileNameInput,
  handleSetHistoryToCollapse,
  historyToCollapse,
  ...props
}) => {
  const [itemsState, setItemsState] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [infoModal, setInfoModal] = useState({ show: false, item: {} });
  const [buyOrderButtonLoading, setBuyOrderButtonLoading] = useState(false);
  const [deliveryDate, setDeliveryDate] = useState("");
  const [newDeliveryArray, setNewDeliveryArray] = useState([]);
  const [deliveryNumber, setDeliveryNumber] = useState([]);

  const { ARS, USD } = useFormatCurrency();
  const { handleDownloadBuyOrder } = useDownloadBuyOrder();
  
  useEffect(() => {
    setItemsState(
      itemArr &&
        itemArr[0]?.buy_order_items?.map((orderItemVal) => {
          return {
            buy_order_item_id: orderItemVal?.id,
            qty_sent: orderItemVal?.quantity - orderItemVal?.quantity_sent,
            delivery_time: orderItemVal?.days_delivery_time_block,
          };
        }),
    );
  }, [itemArr]);

  function handleScrollTo() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  useEffect(() => {
    if (item && item.buy_order_items) {
      const deliveryArray = item?.buy_order_items.reduce((acc, it) => {
        if (!acc[it?.days_delivery_time_block]) {
          acc[it?.days_delivery_time_block] = {
            days_delivery_time_block: [
              {
                ...it,
                days_delivery_time_block: it.days_delivery_time_block,
              },
            ],
          };
        } else {
          acc[it?.days_delivery_time_block]?.days_delivery_time_block.push({
            ...it,
            days_delivery_time_block: it.days_delivery_time_block,
          });
        }

        return acc;
      }, {});

      setNewDeliveryArray(Object.values(deliveryArray));
    }
    let _deliveryNumber = item?.buy_order_items
      ?.map((i) => i?.days_delivery_time_block)
      .reduce(function (a, b) {
        return Math.max(a, b);
      }, -Infinity);
    setDeliveryNumber(_deliveryNumber);
  }, [item]);

  useEffect(() => {
    let sum = 0;
    let deliveryNumbers = [];

    if (
      !showitems &&
      item?.buy_order_items &&
      item?.buy_order_items.length > 0
    ) {
      item.buy_order_items.map((m) => {
        if (!deliveryNumbers[m?.days_delivery_time_block]) {
          deliveryNumbers[m?.days_delivery_time_block] = {
            days_delivery_time_block: [
              {
                ...m,
                days_delivery_time_block: m.days_delivery_time_block,
              },
            ],
          };
        } else {
          deliveryNumbers[
            m?.days_delivery_time_block
          ]?.days_delivery_time_block.push({
            ...m,
            days_delivery_time_block: m.days_delivery_time_block,
          });
        }
        return (sum += m.quantity);
      });

      setTotalItems(sum);

      const maxDays = Math.max(
        ...Object.keys(deliveryNumbers).map((a) => Number(a)),
      );

      setDeliveryDate(
        deliveryNumbers[maxDays]?.days_delivery_time_block[0]
          ?.date_delivery_time_block,
      );
    }
    if (item?.buy_order_items && item?.buy_order_items.length === 0) {
      setTotalItems(0);
    }
  }, [item.buy_order_items, showitems]);

  //parsed variables
  const parsedArs = parseInt(item?.total_w_shipping_ARP) !== 0;
  const parsedUsd = parseInt(item?.total_w_shipping_USD) !== 0;
  const parsedShippingArs = parseInt(item?.shipping_cost_ARP) !== 0;
  const parsedShippingUsd = parseInt(item?.shipping_cost_USD) !== 0;

  const handleChangeInfoModal = (item) => {

    let newInfoModal = PRO_TENANTS.includes(tenant) ? {
        title: "Bekeu Negocios S.A.U",
        business_name: "Bekeu Negocios S.A.U",
        cuit: "30-71748832-2",
        email: "facturacion@bekeu.com",
        condIva: 0,
      } : {
        title: (item?.company?.alias),
        business_name: item?.company?.alias + ' (' + item?.company?.name + ')',
        email: item?.client.user?.email,
        phone: item?.client.user?.phone,
        condIva: 0,
      }

    item
      ? setInfoModal({ show: true, item: newInfoModal })
      : setInfoModal({ show: false, item: {} });
  };

  return (
    <BoxBekeu {...props} p="0">
      <Flex flexDir={"column"}>
        <Grid
          templateColumns={["1fr", "1fr", "1fr", "auto auto"]}
          gap={["1.5rem", "1.5rem", "1.5rem", "unset"]}
          alignItems={"center"}
          justifyContent={"space-between"}
          p="1.5rem"
        >
          <GridItem flexDir={"column"} gap={1}>
            <Flex flexDir={["column", "column", "row", "row"]} mb={1}>
              <Text fontWeight={600} fontSize={"1.125rem"} mr="2">
                Fecha de compra:
              </Text>
              <H6 fontWeight="400">
                {moment(item?.exported_at).format("DD/MM/YYYY")}
              </H6>
            </Flex>
            {!showitems ? (
              <Flex flexDir={["column", "column", "row", "row"]} mb={1}>
                <Text fontWeight={600} fontSize={"1.125rem"} mr="2">
                  Fecha estimada de entrega:
                </Text>
                <H6 fontWeight="400">
                  {moment(deliveryDate).format("DD/MM/YYYY")}
                </H6>
              </Flex>
            ) : (
              <>
                <Grid gap={1}>
                  <Flex flexDir={["column", "column", "row", "row"]}>
                    <Text
                      mr="2"
                      color={"brand.contentSecondary"}
                      fontWeight={"semibold"}
                    >
                      Empresa cliente:
                    </Text>
                    <Text
                      color="brand.primary"
                      fontWeight={600}
                      ml={1}
                      cursor={"pointer"}
                      onClick={(e) => handleChangeInfoModal(item)}
                    >
                      {PRO_TENANTS.includes(tenant) ? 'Bekeu Negocios S.A.U' : item?.company?.alias}  
                    </Text>
                  </Flex>

                  <ModalPersonalInfo
                    infoModal={infoModal}
                    handleChangeInfoModal={handleChangeInfoModal}
                  />

                  {PRO_TENANTS.includes(tenant) && (
                    <Flex flexDir={["column", "column", "row", "row"]}>
                      <Text
                        mr="2"
                        color={"brand.contentSecondary"}
                        fontWeight={"semibold"}
                      >
                        Compra por cuenta y orden:
                      </Text>
                      <Text
                        color={"brand.contentSecondary"}
                        fontWeight={400}
                        ml={1}
                      >
                        {item?.client?.company?.alias}
                      </Text>
                    </Flex>
                  )}
                
                  <Flex flexDir={["column", "column", "row", "row"]}>
                    <Text
                      mr="2"
                      color={"brand.contentSecondary"}
                      fontWeight={"semibold"}
                      whiteSpace="nowrap"
                    >
                      Dirección de entrega:
                    </Text>
                    <Text
                      color="brand.contentSecondary"
                      fontWeight={400}
                      ml={1}
                    >
                      {item?.address?.name}
                    </Text>
                  </Flex>

                  <Flex flexDir={["column", "column", "row", "row"]}>
                    <Text
                      mr="2"
                      color={"brand.contentSecondary"}
                      fontWeight={"semibold"}
                    >
                      Comprador:
                    </Text>
                    <Text
                      color="brand.contentSecondary"
                      fontWeight={400}
                      ml={1}
                    >
                      {item?.client?.user?.name} {item?.client?.user?.last_name}{" "}
                      - {item?.client?.user?.email}
                    </Text>
                  </Flex>
                </Grid>
              </>
            )}
          </GridItem>

          <Grid>
            <GridItem>
              <HStack
                flexDir={["column", "column", "row"]}
                gap={[4]}
                alignItems={"center"}
              >
                {item?.erp_number && (
                  <Box>
                    <Text
                      fontWeight={400}
                      fontSize={"1rem"}
                      border="0.06rem solid"
                      borderColor={"brand.contentSecondary"}
                      padding={"0.5rem"}
                      px={"0.75rem"}
                      borderRadius={"0.75rem"}
                      color="brand.contentSecondary"
                    >
                      N° de compra: {item?.erp_number}
                    </Text>
                  </Box>
                )}
                {!showitems && (
                  <Box>
                    <SalesBadge type={type} item={item} state={item.state}>
                      {item?.state === "2"
                        ? "Pendiente de envío"
                        : item?.state === "2.1"
                          ? "Enviada"
                          : item?.state === "3"
                            ? "Cancelada"
                            : item?.state === "3.2"
                              ? "Cancelada"
                              : item?.state === "4"
                                ? "Confirmada por cliente"
                                : item?.state === "5"
                                  ? "Reclamada por cliente"
                                  : "Pendiente de envío"}
                    </SalesBadge>
                  </Box>
                )}
                {showitems ? (
                    <>
                      <Box>
                        <SalesBadge
                          type={type}
                          item={item}
                          state={item.state}
                          w={"12.75rem"}
                          textAlign={"center"}
                        >
                          {item?.state === "2"
                            ? "Entrega en proceso"
                            : item?.state === "2.1"
                              ? "Enviada"
                              : item?.state === "3"
                                ? "Cancelada"
                                : item?.state === "3.2"
                                  ? "Cancelada"
                                  : item?.state === "4"
                                    ? "Confirmada por cliente"
                                    : item?.state === "5"
                                      ? "Reclamada por cliente"
                                      : "Entrega en proceso"}
                        </SalesBadge>
                      </Box>
                    </>
                ) : (
                  ""
                )}
              </HStack>
            </GridItem>

            <GridItem>
              {showitems && (
                <HStack justifyContent={["center", "center", "flex-end"]}>
                  <Box>
                    <ButtonBekeu
                      leftIcon={
                        <BekeuIcon width={"1.5rem"} src={downloadArroy} />
                      }
                      p={"0.75rem 0.5rem"}
                      height={"2.5rem"}
                      fontSize={"0.875rem"}
                      gap={"0.125rem"}
                      w={"10.5625rem"}
                      disabled={buyOrderButtonLoading}
                      onClick={() => {
                        handleDownloadBuyOrder(
                          "provider",
                          item?.internal_code,
                          "",
                          true,
                          true,
                          tenant
                        );
                        setBuyOrderButtonLoading(true);
                        setTimeout(() => {
                          setBuyOrderButtonLoading(false);
                        }, 6000);
                      }}
                    >
                      Orden de compra
                    </ButtonBekeu>
                  </Box>
                </HStack>
              )}
            </GridItem>
          </Grid>
        </Grid>
        <Divider />
        {!showitems && (
          <>
            {
              <Flex p={"1.5rem 1.5rem 0rem"} gap={2} flexDir={"column"}>
                <Flex>
                  <Text
                    fontWeight={600}
                    fontSize={"1rem"}
                  >
                    Empresa cliente:
                  </Text>
                  <Text
                    color="brand.primary"
                    fontWeight={600}
                    ml={1}
                    cursor={"pointer"}
                    onClick={(e) => handleChangeInfoModal(item)}
                  >
                    {PRO_TENANTS.includes(tenant) ? 'Bekeu Negocios S.A.U' : item?.company?.alias}  
                  </Text>

                  <ModalPersonalInfo
                    infoModal={infoModal}
                    handleChangeInfoModal={handleChangeInfoModal}
                  />
                </Flex>
                
                {PRO_TENANTS.includes(tenant) && (
                  <Flex>
                    <Text
                      fontWeight={600}
                      fontSize={"1rem"}
                    >
                      Compra por cuenta y orden:
                    </Text>
                    <Text ml={1}>{item?.client?.company?.alias}</Text>
                  </Flex>
                )}
              </Flex>
            }

            <Grid
              templateColumns={[
                "auto",
                "repeat(2,1fr)",
                "repeat(3,1fr)",
                "repeat(4,1fr)",
                "1fr 1fr 1fr auto 1fr",
              ]}
              justifyContent={"space-between"}
              p={"1.25rem 1.5rem"}
              gap={12}
            >
              <Flex flexDir={"column"} gap="1.25rem">
                <Paragraphs
                  fontWeight={600}
                  h={"1.7rem"}
                  lineHeight={"1.37rem"}
                  whiteSpace="nowrap"
                >
                  Comprador
                </Paragraphs>

                <Flex flexDir={"column"}>
                  <Text
                    color={"#000000"}
                    fontWeight={400}
                    fontSize={"1rem"}
                    lineHeight={"1.37rem"}
                  >
                    {item?.client?.user?.name} {item?.client?.user?.last_name}
                  </Text>
                  <Text color={"brand.contentSecondary"} fontWeight={400}>
                    {item?.client?.user?.email}
                  </Text>
                </Flex>
              </Flex>
              <Flex flexDir={"column"} gap="1.25rem">
                <Paragraphs
                  fontWeight={600}
                  h={"1.7rem"}
                  lineHeight={"1.37rem"}
                  whiteSpace="nowrap"
                >
                  Orden de compra Nº
                </Paragraphs>
                <Paragraphs lineHeight={"1.37rem"}>
                  {item?.internal_code}
                </Paragraphs>
              </Flex>
              <Flex flexDir={"column"} gap="1.25rem">
                <Paragraphs
                  fontWeight={600}
                  h={"1.7rem"}
                  lineHeight={"1.37rem"}
                  whiteSpace="nowrap"
                >
                  Dirección de entrega
                </Paragraphs>
                <Paragraphs lineHeight={"1.37rem"}>
                  {item?.address?.name}
                </Paragraphs>
              </Flex>
              <Flex flexDir={"column"} gap="1.25rem">
                <Paragraphs
                  fontWeight={600}
                  h={"1.7rem"}
                  lineHeight={"1.37rem"}
                  whiteSpace="nowrap"
                >
                  Comentarios del comprador
                </Paragraphs>
                <Paragraphs lineHeight={"1.37rem"} noOfLines={5}>
                  {!item?.provider_comments ? "-" : item?.provider_comments}
                </Paragraphs>
              </Flex>
              <Flex flexDir={"column"} gap="1.25rem">
                <Paragraphs
                  fontWeight={600}
                  h={"1.7rem"}
                  lineHeight={"1.37rem"}
                  whiteSpace="nowrap"
                >
                  Total de productos{" "}
                </Paragraphs>
                <Paragraphs lineHeight={"1.37rem"}>
                  {item?.buy_order_items && totalItems}
                </Paragraphs>
              </Flex>
            </Grid>

            <Divider />
          </>
        )}

        {showitems &&
          newDeliveryArray.length > 0 &&
          newDeliveryArray.map((deliveryArray, id) => {
            const sentItems = deliveryArray?.days_delivery_time_block.filter(
              (item) => item?.quantity_sent > 0,
            );

            const allQuantitySent =
              deliveryArray?.days_delivery_time_block.filter(
                (item) =>
                  item?.quantity ===
                  item?.quantity_sent + item?.quantity_cancel,
              );

            const allConfirmedItems =
              deliveryArray?.days_delivery_time_block.filter(
                (item) =>
                  item?.quantity ===
                  item?.quantity_confirmed + item?.quantity_cancel,
              );

            const allRejectedItems =
              deliveryArray?.days_delivery_time_block.filter(
                (item) => item?.quantity === item?.quantity_cancel,
              );

            // const confirmedItems = newDeliveryArray?.days_delivery_time_block.filter(
            //   (item) => item?.quantity_confirmed > 0);

            const allQuantitySentValidation =
              allQuantitySent?.length ===
              deliveryArray?.days_delivery_time_block?.length;
            const allConfirmedItemsValidation =
              allQuantitySent?.length === allConfirmedItems.length;
            const allQuantityRejectedValidation =
              allRejectedItems?.length ===
              deliveryArray?.days_delivery_time_block?.length;

            const enableRejectOrder =
              deliveryArray?.days_delivery_time_block?.[0]
                ?.quantity_confirmed ===
              deliveryArray?.days_delivery_time_block?.[0]?.quantity_sent;
            const enableSendItems = !allQuantitySentValidation;

            const blockPending = sentItems.length === 0;
            const blockSent =
              sentItems?.length > 0 &&
              !allConfirmedItemsValidation &&
              !item?.state === "5" &&
              !allQuantityRejectedValidation;
            const blockRejected =
              item?.state === "3.2" || allQuantityRejectedValidation;
            const blockFinished =
              allConfirmedItems.length ===
              deliveryArray?.days_delivery_time_block?.length;
            const blockClaimed =
              item?.state === "5" && !allConfirmedItemsValidation;

            const blockState =
              blockPending && !blockSent && !blockRejected
                ? "2"
                : blockSent
                  ? "2.1"
                  : blockRejected
                    ? "3"
                    : blockFinished
                      ? "4"
                      : blockClaimed
                        ? "5"
                        : item?.state;

            return (
              <React.Fragment key={id}>
                <Flex
                  key={id}
                  px="1.5rem"
                  py="1rem"
                  alignItems={"center"}
                  justifyContent={["center", "center", "space-between","space-between"]}
                  flexWrap={"wrap"}
                  gap={4}
                >
                  <Flex flexDir={["column","column","row" ,"row"]} alignItems={["center","center","unset","unset"]} gap={[4,4,0,0]}>
                    <MaxDeliveryTimeBadge
                      days={
                        deliveryArray?.days_delivery_time_block[0]
                          ?.days_delivery_time_block
                      }
                      dates={moment(
                        deliveryArray?.days_delivery_time_block[0]
                          ?.date_delivery_time_block,
                      ).format("DD/MM/YYYY")}
                      mr="1.5rem"
                    />
                    <ShippingCostBadge
                      currency={
                        parseInt(item?.shipping_cost_ARP) !== 0
                          ? "ARS"
                          : parseInt(item?.shipping_cost_USD) !== 0 && "USD"
                      }
                      shippingCost={
                        (parseInt(item?.shipping_cost_ARP) !== 0 &&
                          item?.shipping_cost_ARP) ||
                        (parseInt(item?.shipping_cost_USD) !== 0 &&
                          item?.shipping_cost_USD)
                      }
                    />
                  </Flex>

                  {/* Estado Badge */}
                  <SalesBadge
                    type={type}
                    item={item}
                    state={blockState}
                    w={"12.75rem"}
                    textAlign={"center"}
                  >
                    {blockState === "2"
                      ? "Pendiente de envío"
                      : blockState === "2.1"
                        ? "Enviada"
                        : blockState === "3"
                          ? "Cancelada"
                          : blockState === "3.2"
                            ? "Cancelada"
                            : blockState === "4"
                              ? "Confirmada por cliente"
                              : blockState === "5"
                                ? "Reclamada por cliente"
                                : ""}
                  </SalesBadge>
                </Flex>

                <Divider />

                {/* Product Details */}
                {deliveryArray?.days_delivery_time_block?.map((product, id) => {
                  return (
                    <DetailItem
                      key={id}
                      item={product}
                      sellerData={item?.provider}
                      getOneProduct={getOneProduct}
                      order={item}
                      itemsState={itemsState}
                      setItemsState={setItemsState}
                      orderItem={item}
                      getHistory={getHistory}
                      newDeliveryArray={deliveryArray}
                      tenant={tenant}
                      handleSetHistoryToCollapse={handleSetHistoryToCollapse}
                      historyToCollapse={historyToCollapse}
                    />
                  );
                })}

                {(blockState === "2" || blockState === "5") &&
                  enableSendItems && (
                    <Flex
                      alignItems={"center"}
                      p="1.5rem"
                      w="100%"
                      gap="0.75rem"
                      justifyContent={["center","center","flex-end","flex-end"]}
                      flexWrap={"wrap"}
                    >
                      {/* Cancelar envio */}
                      <SalesRejectModal
                        handleRejectOrder={handleRejectOrder}
                        item={item}
                        setSellerComments={setSellerComments}
                        sellerComments={sellerComments}
                        handlePagination={handlePagination}
                        handleFillResult={handleFillResult}
                        deliveryTime={
                          deliveryArray?.days_delivery_time_block[0]
                            ?.days_delivery_time_block
                        }
                        itemsState={itemsState.filter(
                          (i) =>
                            i?.delivery_time ===
                            deliveryArray?.days_delivery_time_block[0]
                              ?.days_delivery_time_block,
                        )}
                        enableRejectOrder={enableRejectOrder}
                      />

                      {/* Registrar envio */}
                      <SalesSuccessModal
                        remitNumber={remitNumber}
                        handleApproveOrder={handleApproveOrder}
                        setRemitNumber={setRemitNumber}
                        item={item}
                        handleFillResult={handleFillResult}
                        handlePagination={handlePagination}
                        itemsState={itemsState.filter(
                          (i) =>
                            i?.delivery_time ===
                            deliveryArray?.days_delivery_time_block[0]
                              ?.days_delivery_time_block,
                        )}
                        enableSendOrder={enableSendItems}
                        tenant={tenant}
                        msgFileNameInput={msgFileNameInput}
                        fileNameInput={fileNameInput}
                        handleChangeFileNameInput={handleChangeFileNameInput}
                      />
                    </Flex>
                  )}

                {blockState === "2.1" && enableSendItems && (
                  <Flex
                    alignItems={"center"}
                    p="1.5rem"
                    w="100%"
                    gap="0.75rem"
                    justifyContent={["center","center","flex-end","flex-end"]}
                    flexWrap={"wrap"}
                  >
                    <SalesRejectModal
                      handleRejectOrder={handleRejectOrder}
                      item={item}
                      setSellerComments={setSellerComments}
                      sellerComments={sellerComments}
                      handlePagination={handlePagination}
                      handleFillResult={handleFillResult}
                      deliveryTime={
                        deliveryArray?.days_delivery_time_block[0]
                          ?.days_delivery_time_block
                      }
                      itemsState={itemsState.filter(
                        (i) =>
                          i?.delivery_time ===
                          deliveryArray?.days_delivery_time_block[0]
                            ?.days_delivery_time_block,
                      )}
                      enableRejectOrder={enableRejectOrder}
                    />
                    <SalesSuccessModal
                      remitNumber={remitNumber}
                      handleApproveOrder={handleApproveOrder}
                      setRemitNumber={setRemitNumber}
                      item={item}
                      handleFillResult={handleFillResult}
                      handlePagination={handlePagination}
                      itemsState={itemsState.filter(
                        (i) =>
                          i?.delivery_time ===
                          deliveryArray?.days_delivery_time_block[0]
                            ?.days_delivery_time_block,
                      )}
                      enableSendOrder={enableSendItems}
                      tenant={tenant}
                      msgFileNameInput={msgFileNameInput}
                      fileNameInput={fileNameInput}
                      handleChangeFileNameInput={handleChangeFileNameInput}
                    />
                  </Flex>
                )}
              </React.Fragment>
            );
          })}

        {!showitems && (
          <Flex
            flexDir={"column"}
            alignItems={["flex-start", "flex-start", "flex-end", "flex-end"]}
            marginInline={["1.5rem"]}
            gap={"0.5rem"}
            mt={"1.5rem"}
            mb={"1rem"}
          >
            {parsedArs && (
              <>
                <Flex gap={"0.75rem"} alignItems="center">
                  <H6 fontWeight="400">Subtotal en ARS:</H6>
                  <H6 fontWeight="400">
                    {ARS.format(parseFloat(item?.total_ARP))} + Imp{" "}
                  </H6>
                </Flex>
                {!parsedShippingArs ? (
                  <Flex gap={"0.75rem"} alignItems="center">
                    <H6>Costo envío en ARS:</H6>
                    <H6 color="brand.success">Envío Incluído</H6>
                  </Flex>
                ) : (
                  <Flex gap={"0.75rem"} alignItems="center">
                    <H6 fontWeight="400">Costo envío en ARS:</H6>
                    <H6 fontWeight="400">
                      {ARS.format(parseFloat(item?.shipping_cost_ARP))} + Imp{" "}
                    </H6>
                  </Flex>
                )}
                <Flex gap={"0.75rem"}>
                  <H6>Total en ARS:</H6>
                  <H6>
                    {ARS.format(parseFloat(item?.total_w_shipping_ARP))} + Imp{" "}
                  </H6>
                </Flex>
              </>
            )}
            {parsedUsd && (
              <>
                <H6 fontWeight="400">
                  Subtotal en USD: {USD.format(parseFloat(item?.total_USD))} +
                  Imp{" "}
                </H6>
                {!parsedShippingUsd ? (
                  <Flex
                    gap={["0", "0.75rem", "0.75rem", "0.75rem"]}
                    flexDir={["column", "row", "row", "row"]}
                    alignItems={["unset", "center", "center", "center"]}
                  >
                    <H6 fontWeight={400}> Costo envío en USD: </H6>
                    <H6 color="brand.success">Envío Incluído</H6>
                  </Flex>
                ) : (
                  <H6 fontWeight={"400"}>
                    Costo envío en USD:{" "}
                    {USD.format(parseFloat(item?.shipping_cost_USD))} + Imp{" "}
                  </H6>
                )}
                <Flex
                  gap={["0", "0.75rem", "0.75rem", "0.75rem"]}
                  flexDir={["column", "row", "row", "row"]}
                  alignItems={["unset", "center", "center", "center"]}
                >
                  <H6>Total en USD:</H6>
                  <H6>
                    {USD.format(parseFloat(item?.total_w_shipping_USD))} + Imp{" "}
                  </H6>
                </Flex>
              </>
            )}
          </Flex>
        )}
        <Flex
          justifyContent={["center", "center", "flex-end", "flex-end"]}
          alignItems={"center"}
          flexWrap={"wrap"}
        >
          {!selected && (
            <>
              {(
                <ButtonBekeu
                  leftIcon={<BekeuIcon width={"1.5rem"} src={downloadArroy} />}
                  w={"10.5625rem"}
                  p={"0.75rem 0.5rem"}
                  height={"2.5rem"}
                  fontSize={"0.875rem"}
                  gap={"0.125rem"}
                  disabled={buyOrderButtonLoading}
                  onClick={() => {
                    setBuyOrderButtonLoading(true);
                    handleDownloadBuyOrder(
                      "provider",
                      item?.internal_code,
                      "",
                      true,
                      true,
                      tenant
                    );
                    setTimeout(() => {
                      setBuyOrderButtonLoading(false);
                    }, 6000);
                  }}
                >
                  Orden de compra
                </ButtonBekeu>
              )}
              <Link
                to={`/sales/order-detail/${item.id}/?state=${item.state}&tenant=${tenant}`}
                _focus={{ boxShadow: "rgba(0,0,0,0)" }}
              >
                <ButtonBekeuLink
                  leftIcon={<BekeuIcon src={eye} />}
                  p="1.5rem"
                  onClick={() => {
                    handleScrollTo();
                  }}
                >
                  Ver Pedido
                </ButtonBekeuLink>
              </Link>
              {(item.state === "2" || item.state === "2.1") && showitems && (
                <Flex
                  alignItems={"center"}
                  p="1.5rem"
                  w="100%"
                  gap="0.75rem"
                  justifyContent={["center","center","flex-end","flex-end"]}
                  flexWrap={"wrap"}
                >
                  <SalesRejectModal
                    handleRejectOrder={handleRejectOrder}
                    item={item}
                    setSellerComments={setSellerComments}
                    sellerComments={sellerComments}
                    handlePagination={handlePagination}
                    deliveryTime={deliveryNumber}
                    itemsState={itemsState}
                    handleFillResult={handleFillResult}
                    //enableRejectOrder={enableRejectOrder}
                  />
                  <SalesSuccessModal
                    remitNumber={remitNumber}
                    handleApproveOrder={handleApproveOrder}
                    setRemitNumber={setRemitNumber}
                    item={item}
                    handlePagination={handlePagination}
                    itemsState={itemsState}
                    handleFillResult={handleFillResult}
                    //enableSendOrder={enableSendItems}
                  />
                </Flex>
              )}
            </>
          )}
        </Flex>

        {showitems &&
          /*(PRO_TENANTS.includes(tenant) ? (*/
            <SalesSummary
              parsedArs={parsedArs}
              parsedUsd={parsedUsd}
              parsedShippingArs={parsedShippingArs}
              parsedShippingUsd={parsedShippingUsd}
              item={item}
              tenant={tenant}
            />
          /*) : (
            <Grid>
              <GridItem>
                <Text
                  py="1rem"
                  px="1.5rem"
                  fontSize={"1.25rem"}
                  fontWeight={400}
                  lineHeight="1.68rem"
                >
                  Resumen de compra
                </Text>
              </GridItem>

              <Divider />

              <Flex py="1.5rem">
                <Flex flexDir={"column"} gap="0.75rem">
                  {parsedArs && (
                    <H6 px="1.5rem" fontWeight={400}>
                      Subtotal en ARS
                    </H6>
                  )}
                  {parsedUsd && (
                    <H6 px="1.5rem" fontWeight={400}>
                      Subtotal en USD
                    </H6>
                  )}
                  {parsedArs && (
                    <H6 px="1.5rem" fontWeight={400}>
                      Costo envío en ARS
                    </H6>
                  )}
                  {parsedUsd && (
                    <H6 px="1.5rem" fontWeight={400}>
                      Costo envío en USD
                    </H6>
                  )}
                  {parsedArs && <H6 px="1.5rem">Total en ARS</H6>}
                  {parsedUsd && <H6 px="1.5rem">Total en USD</H6>}
                </Flex>
                <Flex flexDir={"column"} gap="0.75rem">
                  {parsedArs && (
                    <H6 px="0.75rem" fontWeight={400}>
                      {item?.total_ARP} + Imp
                    </H6>
                  )}
                  {parsedUsd && (
                    <H6 px="0.75rem" fontWeight={400}>
                      {item?.total_USD} + Imp
                    </H6>
                  )}
                  {parsedArs &&
                    (parsedShippingArs ? (
                      <H6 px="0.75rem" fontWeight={400}>
                        {item?.shipping_cost_ARP} + Imp
                      </H6>
                    ) : (
                      <H6 px="0.75rem" color="brand.success">
                        Envío Incluído
                      </H6>
                    ))}
                  {parsedUsd &&
                    (parsedShippingUsd ? (
                      <H6 px="0.75rem" fontWeight={400}>
                        {item?.shipping_cost_USD} + Imp
                      </H6>
                    ) : (
                      <H6 px="0.75rem" color="brand.success">
                        Envío Incluído
                      </H6>
                    ))}
                  {parsedArs && (
                    <H6 px="0.75rem">{item?.total_w_shipping_ARP} + Imp</H6>
                  )}
                  {parsedUsd && (
                    <H6 px="0.75rem">{item?.total_w_shipping_USD} + Imp</H6>
                  )}
                </Flex>
              </Flex>
            </Grid>
          )*/}
      </Flex>
    </BoxBekeu>
  );
};

export default injectIntl(connect(null, { ...product.actions })(SalesItem));
