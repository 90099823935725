import {
  Flex,
  Img,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import { H5 } from "../../design-system/headings/H5";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import success from "../../design-system/icons/Arrows/success-circle-fill.svg";
import { useHistory } from "react-router-dom";

export const CredentialsSAPSuccessModal = ({ isOpen, onClose, setResult }) => {
  const history = useHistory();

  const handleModalClose = () => {
    onClose();
    setResult(null);
    history.push("/");
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
      <ModalOverlay />
      <ModalContent borderRadius={"0.75rem"}>
        <ModalCloseButton
          onClick={handleModalClose}
          _focus={{ boxShadow: "rgba(0,0,0,0)" }}
        />
        <ModalBody>
          <Flex
            flexDir={"column"}
            justifyContent="center"
            padding={"3rem"}
            alignItems="center"
          >
            <Img src={success} />
            <H5 textAlign="center" mt="1.75rem">
              Recibimos tus Credenciales SAP
            </H5>
            <Paragraphs my={"1.75rem"} className="dz-message" data-dz-message>
              <Text
                color={"brand.contentSecondary"}
                textAlign={"center"}
                paddingInline={"6"}
                lineHeight={"base"}
              >
                Las credenciales serán cargadas en la plataforma dentro de un
                lapso de 24 hs hábiles.
              </Text>
            </Paragraphs>

            <ButtonBekeu
              onClick={handleModalClose}
              p={"0.75rem"}
              maxW={"13rem"}
              mt={0}
            >
              Volver al inicio
            </ButtonBekeu>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CredentialsSAPSuccessModal;
