import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import * as auth from "../pages/login/ducks/auth.duck";
import * as productsGrid from "../pages/productGridPage/ducks/productsGrid.duck";
import * as categories from "../pages/categoriesPage/ducks/categories.duck";
import * as cart from "./cart/cart.duck";
import * as orders from "../pages/approveOrdersPage/ducks/approveOrders.duck";
import * as sales from "../pages/salesPage/ducks/sales.duck";
import * as delegation from "../pages/delegateOrdersPage/ducks/delegateOrders.duck";
import * as quotes from "../pages/myQuotesPage/ducks/myQuotes.duck";
import * as carts from "../pages/myOrdersPage/ducks/myOrders.duck";
import * as carts_approval from "../pages/approveCartsPage/ducks/approveCarts.duck";
import * as shipping from "../pages/ShippingPage/ducks/shipping.duck";
import * as stats from "../pages/myStatsPage/ducks/myStats.duck";
import * as clients from "../pages/whiteListPage/ducks/ProviderWhiteList.duck";
import * as providers from "../pages/whiteListPage/ducks/ClientWhiteList.duck";
import * as productsRequest from "../pages/RequestProduct/ducks/requestProduct.duck";
import * as credentialsSAP from "../pages/CredentialsSAP/ducks/CredentialsSAP.duck";
import { toronto } from "../../_toronto";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  i18n: toronto.i18n.reducer,
  productsGrid: productsGrid.reducer,
  categories: categories.reducer,
  cart: cart.reducer,
  orders: orders.reducer,
  carts: carts.reducer,
  carts_approval: carts_approval.reducer,
  sales: sales.reducer,
  delegation: delegation.reducer,
  quotes: quotes.reducer,
  shipping: shipping.reducer,
  stats: stats.reducer,
  clients : clients.reducer,
  providers : providers.reducer,
  productsRequest: productsRequest.reducer,
  credentialsSAP: credentialsSAP.reducer,
});

export function* rootSaga() {
  yield all([
    auth.saga(),
    productsGrid.saga(),
    cart.saga(),
    orders.saga(),
    sales.saga(),
    carts.saga(),
    carts_approval.saga(),
    categories.saga(),
    delegation.saga(),
    quotes.saga(),
    shipping.saga(),
    stats.saga(),
    clients.saga(),
    providers.saga(),
    productsRequest.saga(),
    credentialsSAP.saga(),
  ]);
}
