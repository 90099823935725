import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import {
  listApprovalCarts,
  approveCart,
  rejectCart,
  approveCartOrder,
  rejectCartOrder,
  cartDetail,
} from "../cruds/approveCarts.crud";

export const actionTypes = {
  LIST_APPROVAL_CARTS: "LIST_APPROVAL_CARTS",
  FILL_APPROVAL_CARTS_LIST: "FILL_APPROVAL_CARTS_LIST",
  FILL_APPROVAL_CART_PAGINATION_DATA: "FILL_APPROVAL_CART_PAGINATION_DATA",
  APPROVE_CART: "APPROVE_CART",
  REJECT_CART: "REJECT_CART",
  APPROVE_CART_ORDER: "APPROVE_CART_ORDER",
  REJECT_CART_ORDER: "REJECT_CART_ORDER",
  APPROVED_CARTS_LIST: "APPROVED_CARTS_LIST",
  SET_APPROVAL_RESULT: "SET_APPROVAL_RESULT",
  SET_APPROVAL_RESULT_VALUE: "SET_APPROVAL_RESULT_VALUE",
  CART_APPROVAL_DETAIL: "CART_APPROVAL_DETAIL",
  FILL_APPROVAL_CART_DETAIL: "FILL_APPROVAL_CART_DETAIL",
  APPROVAL_RESULT_FAIL: "APPROVAL_RESULT_FAIL",
};

const initialState = {
  orders: [],
  carts: [],
  cart: [],
  state: 1,
  approvalLoading: false,
  cartsPagination: {
    page: 1,
    perPage: 20,
    totalPages: 1,
    totalRows: 0,
  },
  approvalResult: null,
  approvalResultValue: null,
  rejectResult: null,
  approvalResultFail: { error: "", status: false },
};

export const reducer = persistReducer(
  { storage, key: "carts_approval", whitelist: ["carts_approval"] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.LIST_APPROVAL_CARTS: {
        return {
          ...state,
          approvalLoading: true,
        };
      }

      case actionTypes.FILL_APPROVAL_CARTS_LIST: {
        return {
          ...state,
          carts: action.payload,
          approvalLoading: false,
        };
      }

      case actionTypes.FILL_APPROVAL_CART_PAGINATION_DATA: {
        return {
          ...state,
          cartsPagination: action.payload,
        };
      }

      case actionTypes.APPROVE_CART: {
        return {
          ...state,
          approvalLoading: false,
        };
      }

      case actionTypes.REJECT_CART: {
        return {
          ...state,
          approvalLoading: false,
        };
      }
      case actionTypes.SET_APPROVAL_RESULT_VALUE: {
        return {
          ...state,
          approvalResultValue: action.payload,
          approvalLoading: false,
        };
      }

      case actionTypes.APPROVE_CART_ORDER: {
        return {
          ...state,
          approvalLoading: false,
        };
      }

      case actionTypes.APPROVAL_RESULT_FAIL: {
        return {
          ...state,
          approvalRejectResultFail: action.payload,
        };
      }

      case actionTypes.REJECT_CART_ORDER: {
        return {
          ...state,
          approvalLoading: false,
        };
      }

      case actionTypes.APPROVED_CARTS_LIST: {
        return {
          ...state,
          approvalLoading: true,
        };
      }

      case actionTypes.SET_APPROVAL_RESULT: {
        return {
          ...state,
          approvalResult: action.payload,
          rejectResult: action.payload,
          approvalLoading: false,
        };
      }

      case actionTypes.CART_APPROVAL_DETAIL: {
        return {
          ...state,
          approvalLoading: true,
        };
      }

      case actionTypes.FILL_APPROVAL_CART_DETAIL: {
        return {
          ...state,
          cart: action.payload,
          approvalLoading: false,
        };
      }

      default:
        return state;
    }
  },
);

export const actions = {
  listApprovalCarts: (value) => ({
    type: actionTypes.LIST_APPROVAL_CARTS,
    payload: value,
  }),
  fillCartsList: (value) => ({
    type: actionTypes.FILL_APPROVAL_CARTS_LIST,
    payload: value,
  }),
  fillCartsPaginationData: (value) => ({
    type: actionTypes.FILL_APPROVAL_CART_PAGINATION_DATA,
    payload: value,
  }),
  approveCart: (value) => ({
    type: actionTypes.APPROVE_CART,
    payload: value,
  }),
  approvalRejectResultFail: (value) => ({
    type: actionTypes.APPROVAL_RESULT_FAIL,
    payload: value,
  }),
  rejectCart: (value) => ({
    type: actionTypes.REJECT_CART,
    payload: value,
  }),
  approveCartOrder: (value) => ({
    type: actionTypes.APPROVE_CART_ORDER,
    payload: value,
  }),
  rejectCartOrder: (value) => ({
    type: actionTypes.REJECT_CART_ORDER,
    payload: value,
  }),
  approvedCartsList: (value) => ({
    type: actionTypes.APPROVED_CARTS_LIST,
    payload: value,
  }),
  approvalResultValue: (value) => ({
    type: actionTypes.SET_APPROVAL_RESULT_VALUE,
    payload: value,
  }),
  approvalResult: (value) => ({
    type: actionTypes.SET_APPROVAL_RESULT,
    payload: value,
  }),
  rejectResult: (value) => ({
    type: actionTypes.SET_APPROVAL_RESULT,
    payload: value,
    approvalLoading: false,
  }),
  cartDetail: (value) => ({
    type: actionTypes.CART_APPROVAL_DETAIL,
    payload: value,
  }),
  fillCartDetail: (value) => ({
    type: actionTypes.FILL_APPROVAL_CART_DETAIL,
    payload: value,
  }),
};

export const getState = (state) => state;

export function* saga() {
  yield takeLatest(
    actionTypes.LIST_APPROVAL_CARTS,
    function* listCartsSaga({ payload }) {
      try {
        const response = yield listApprovalCarts(payload);
        const data = response.data.message.data.map((item) => {
          switch (item.state) {
            case "1":
              item.status = "pending";
              break;
            case "2":
              item.status = "approved";
              break;
            case "3":
              item.status = "rejected";
              break;
            default:
              break;
          }
          return item;
        });

        const cartsPagination = response.data.message.pagination;

        if (response.status === 200) {
          yield put(actions.fillCartsList(data));
          yield put(actions.fillCartsPaginationData(cartsPagination));
          yield put(actions.approvalResult(false));
          yield put(actions.rejectResult(false));
        } else {
          yield put(actions.fillCartsList([]));
          yield put(actions.fillCartsPaginationData(cartsPagination));
          yield put(actions.approvalResult(false));
          yield put(actions.rejectResult(false));
        }
      } catch (e) {
        yield put(actions.fillCartsList([]));
        yield put(actions.approvalResult(false));
        yield put(actions.rejectResult(false));
      }
    },
  );

  yield takeLatest(
    actionTypes.APPROVE_CART,
    function* approveCartsSaga({ payload }) {
      try {
        const { data } = yield approveCart(payload);
        if (data.status === "success") {
          yield put(
            actions.approvalRejectResultFail({
              error: "",
              status: false,
            }),
          );
          yield put(actions.approvalResult(true));
          yield put(actions.approvalResultValue(data));
          yield put(actions.rejectResult(false));
        } else {
          yield put(actions.approvalResult(false));
          yield put(actions.approvalResultValue(data));
          yield put(actions.rejectResult(false));
        }
      } catch (error) {
        yield put(
          actions.approvalRejectResultFail({
            error: "Por favor recargue la página",
            status: true,
          }),
        );
        yield put(actions.approvalResult(false));
        yield put(actions.rejectResult(false));
      }
    },
  );

  yield takeLatest(
    actionTypes.REJECT_CART,
    function* rejectCartsSaga({ payload }) {
      try {
        const { data } = yield rejectCart(payload);
        if (data.code === 200) {
          yield put(
            actions.approvalRejectResultFail({
              error: "",
              status: false,
            }),
          );
          yield put(actions.approvalResult(false));
          yield put(actions.rejectResult(true));
        } else {
          yield put(actions.approvalResult(false));
          yield put(actions.rejectResult(false));
        }
      } catch (e) {
        yield put(
          actions.approvalRejectResultFail({
            error: "Por favor recargue la página",
            status: true,
          }),
        );
        yield put(actions.approvalResult(false));
        yield put(actions.rejectResult(false));
      }
    },
  );

  yield takeLatest(
    actionTypes.APPROVE_CART_ORDER,
    function* approveCartOrdersSaga({ payload }) {
      try {
        const { data } = yield approveCartOrder(payload);
        if (data.code === 200) {
          yield put(actions.approvalResult(true));
          yield put(actions.rejectResult(false));
        } else {
          yield put(actions.approvalResult(false));
          yield put(actions.rejectResult(false));
        }
      } catch (e) {
        yield put(actions.approvalResult(false));
        yield put(actions.rejectResult(false));
      }
    },
  );

  yield takeLatest(
    actionTypes.REJECT_CART_ORDER,
    function* rejectCartOrdersSaga({ payload }) {
      try {
        const { data } = yield rejectCartOrder(payload);
        if (data.code === 200) {
          yield put(actions.approvalResult(false));
          yield put(actions.rejectResult(true));
        } else {
          yield put(actions.approvalResult(false));
          yield put(actions.rejectResult(false));
        }
      } catch (e) {
        yield put(actions.approvalResult(false));
        yield put(actions.rejectResult(false));
      }
    },
  );

  yield takeLatest(
    actionTypes.CART_APPROVAL_DETAIL,
    function* cartDetailSaga({ payload }) {
      try {
        const response = yield cartDetail(payload);

        const data = response.data.message.data;

        if (response.status === 200) {
          yield put(actions.fillCartDetail(data));
        }
      } catch (e) {
      }
    },
  );
}
