import { put, takeLatest } from "redux-saga/effects";
import {
  getCredentialsSAP,
  storeCredentialsSAP,
  testCredentialsSAP,
} from "../cruds/CredentialsSAP.crud";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  STORE_CREDENTIALS_SAP: "STORE_CREDENTIALS_SAP",
  GET_CREDENTIALS_SAP: "GET_CREDENTIALS_SAP",
  TEST_CREDENTIALS_SAP: "TEST_CREDENTIALS_SAP",
  SET_RESULT: "SET_RESULT",
  SET_CREDENTIAL_VALID: "SET_CREDENTIAL_VALID",
};

const initialState = {
  result: null,
  loadingSend: false,
  loadingTest: false,
  credentialValid: null,
};

export const reducer = persistReducer(
  { storage, key: "demo1-credentialsSAP", whitelist: ["credentials"] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.STORE_CREDENTIALS_SAP:
        return { ...state, loadingSend: true };
      case actionTypes.GET_CREDENTIALS_SAP:
        return { ...state, loadingSend: false, loadingTest: false };
      case actionTypes.TEST_CREDENTIALS_SAP:
        return { ...state, loadingTest: true, credentialValid: null };
      case actionTypes.SET_RESULT:
        return {
          ...state,
          result: action.payload,
          loadingSend: false,
          loadingTest: false,
        };
      case actionTypes.SET_CREDENTIAL_VALID:
        return {
          ...state,
          credentialValid: action.payload,
          loadingSend: false,
          loadingTest: false,
        };
      default:
        return state;
    }
  }
);

export const actions = {
  storeCredentialsSAP: (payload) => ({
    type: actionTypes.STORE_CREDENTIALS_SAP,
    payload,
  }),
  getCredentialsSAP: () => ({ type: actionTypes.GET_CREDENTIALS_SAP }),
  testCredentialsSAP: (payload) => ({
    type: actionTypes.TEST_CREDENTIALS_SAP,
    payload,
  }),
  setResult: (value) => ({ type: actionTypes.SET_RESULT, payload: value }),
  setCredentialValid: (value) => ({
    type: actionTypes.SET_CREDENTIAL_VALID,
    payload: value,
  }),
};

export function* saga() {
  yield takeLatest(
    actionTypes.STORE_CREDENTIALS_SAP,
    function* storeCredentialsSAPSaga({ payload }) {
      try {
        const response = yield storeCredentialsSAP(payload);
        if (response.data.status === "success") {
          yield put(actions.setResult(true));
        } else {
          yield put(actions.setResult(false));
        }
      } catch (err) {
        yield put(actions.setResult(false));
      }
    }
  );

  yield takeLatest(
    actionTypes.GET_CREDENTIALS_SAP,
    function* getCredentialsSAPSaga() {
      try {
        const response = yield getCredentialsSAP();
        const data = response.data.message;
        if (response.data.status === "success") {
          yield put(actions.setResult(data));
        } else {
          yield put(actions.setResult(false));
        }
      } catch (err) {
        yield put(actions.setResult(false));
      }
    }
  );

  yield takeLatest(
    actionTypes.TEST_CREDENTIALS_SAP,
    function* testCredentialsSAPSaga({ payload }) {
      try {
        const response = yield testCredentialsSAP(payload);
        if (response.data.status === "success") {
          yield put(actions.setCredentialValid(true));
        } else {
          yield put(actions.setCredentialValid(false));
        }
      } catch (err) {
        yield put(actions.setCredentialValid(false));
      }
    }
  );
}
