import React, { useState } from "react";
import {
  Button,
  Divider,
  Flex,
  Img,
  Text,
  Box,
  Tooltip
} from "@chakra-ui/react";
import { H6 } from "../../design-system/headings/H6";
import trash from "../../design-system/icons/Cart/trash.svg";
import { useEffect } from "react";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import { useHistory } from "react-router-dom";
import { ProductDetailModalSeller } from "../ProductDetailModalSeller/ProductDetailModalSeller";
import { InputBekeu } from "../../design-system/inputs/InputBekeu";
import notFoundImage from "../../design-system/images/not-found.png";
import { useSelector } from "react-redux";
import MenuCeco from "./MenuCeco";
import infoCircle from "../../design-system/icons/Shapes/info-circle.svg";
import useFormatCurrency from "../../hooks/useFormatCurrency";

export const CartItem = ({
  item,
  onQuantityChange,
  handleRemoveCartItem,
  handleSelectedCeco,
  handleDeleteCeco,
  handleDisabledCeco,
  setEnablePercentage,
  handleApplyAllCecos,
  allCecos,
  index,
  hasApplyAllCecos,
  sapAvailable,
}) => {
  //useState
  const [quantity, setQuantity] = useState(0);
  const [quantityInputEnabled, setQuantityInputEnabled] = useState(true);
  const [imageError, setImageError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState({});
  const [timeoutId, setTimeoutId] = useState(null);


  //hooks
  const history = useHistory();
  const authState = useSelector((state) => state?.auth);
  const cartState = useSelector((state) => state?.cart);

  const { ARS, USD } = useFormatCurrency();

  useEffect(() => {
    setProduct(item)
    setQuantity(item?.quantity);
  }, [item])

  // useEffect
  // useEffect(() => {
  //   quantityInputEnabled
  //     ? onQuantityChange(product, Number(quantity))
  //     : setQuantityInputEnabled(true);
  // }, [quantity]);


  useEffect(() => {
    cartState?.result && setLoading(false);
  }, [cartState?.result]);

  useEffect(() => {
    setLoading(false);
  }, [cartState?.costCenter]);

  //functions
  function handleQuantityChange(e) {

    // Limpia el temporizador anterior
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    
    let quantity = 1
    setQuantity(1);
    
    if (e.target.value !== "0") {
      setQuantity((quantity) => (quantity = validate));
      let validate = e.target.value.replace(/[^0-9]+/g, "");
      quantity = validate;
    }
    


    // Configura un nuevo temporizador para ejecutar la función después de 3 segundos
    const newTimeoutId = setTimeout(() => {
      quantityInputEnabled
        ? onQuantityChange(product, Number(quantity))
        : setQuantityInputEnabled(true);
    }, 1300);

    // Guarda el ID del nuevo temporizador
    setTimeoutId(newTimeoutId);

  }

  function handleNoProductCart() {
    return history.push(`/detail/${product.sku}`);
  }

  const handleSelectedCecoItem = (obj) => {
    handleSelectedCeco(obj, product);
  };

  const handleDeleteCecoItem = (obj) => {
    handleDeleteCeco(obj, product);
  };

  return (
    <Flex flexDir={"column"}>
      <Divider />
      <Flex>
        <Flex
          w={"100%"}
          justifyContent={"space-between"}
          alignItems="center"
          p={"1.5rem"}
        >
          <Flex w={"45%"} alignItems="center" mb={"1rem"}>
            <Box h={"5rem"} w={"5rem"} mr={6}>
              {product?.error ? (
                <Img
                  p={"0.625rem"}
                  w={"5.625rem"}
                  src={imageError ? notFoundImage : product?.image_source}
                  draggable={"false"}
                  onError={() => setImageError(true)}
                />
              ) : (
                <Img
                  p={"0.625rem"}
                  w={"5.625rem"}
                  src={imageError ? notFoundImage : product?.image_source}
                  draggable={"false"}
                  cursor={"pointer"}
                  onClick={() => handleNoProductCart()}
                  onError={() => setImageError(true)}
                />
              )}
            </Box>
            <Flex flexDir={"column"} w={"80%"} justifyContent={"space-evenly"}>
              {product?.error ? (
                <H6 w={"90%"} transition="300ms ease all">
                  {product?.title}
                </H6>
              ) : (
                <H6
                  w={"90%"}
                  cursor="pointer"
                  transition="300ms ease all"
                  onClick={() => handleNoProductCart()}
                >
                  {product?.title}
                </H6>
              )}
              <ProductDetailModalSeller
                sellerName={product?.fantasy_name}
                email={product?.provider_email}
                phone={product?.provider_phone}
              />
              {product?.error && (
                <Text
                  fontSize={"sm"}
                  mb={4}
                  className="animate__animated animate__fadeIn"
                >
                  Este producto ya no se encuentra disponible para comprar
                </Text>
              )}
            </Flex>
          </Flex>
          <Flex w={"65%"} justifyContent={"space-between"} alignItems="center">
            <Flex gap="1.5rem" w="50%">
              <Flex flexDir={"column"}>
                <Text style={{ textAlign: "center" }} mb={"8px"}>
                  Cantidad
                </Text>
                {product?.error ? (
                  <InputBekeu
                    placeholder={1}
                    w={"6.875rem"}
                    type="text"
                    value={quantity}
                    borderColor={"gray"}
                    focusBorderColor="gray.hover"
                    min="0"
                    isReadOnly
                  />
                ) : (
                  <InputBekeu
                    placeholder={1}
                    w={"6.875rem"}
                    type="text"
                    value={quantity}
                    min="0"
                    onChange={handleQuantityChange}
                  />
                )}
              </Flex>
              {authState?.features &&
                authState?.features?.includes("imputation") && (
                  <MenuCeco
                    cecoList={cartState?.costCenter}
                    product={product}
                    cecoSelected={product.cost_centers}
                    handleSelectedCeco={handleSelectedCecoItem}
                    handleDeleteCecoItem={handleDeleteCecoItem}
                    handleDisabledCeco={handleDisabledCeco}
                    setEnablePercentage={setEnablePercentage}
                    handleApplyAllCecos={handleApplyAllCecos}
                    allCecos={allCecos}
                    index={index}
                    hasApplyAllCecos={hasApplyAllCecos}
                    sapAvailable={sapAvailable}
                  />
                )}
            </Flex>

            <Flex
              justifyContent={"space-between"}
              alignItems="center"
              gap="1.5rem"
              w={"60%"}
            >
              <Flex flexDir={"column"}>
                <H6>
                  {product?.currency_code === "ARS"
                    ? ARS.format(parseFloat(product?.total))
                    : USD.format(parseFloat(product?.total))}{" "}
                  + Imp
                  <Box display="inline-block" position="relative">
                    <Tooltip
                      closeDelay={200}
                      p={"12px"}
                      borderTopLeftRadius={"0px"}
                      label={
                        "Costo de envío incluido en el precio."
                      }
                      bg="white"
                      color="black"
                      borderRadius={"12px"}
                      borderWidth={"1px"}
                      placement={"bottom-start"}
                    >
                      <span>
                        <BekeuIcon src={infoCircle} ml="4px" h={"16px"} />
                      </span>
                    </Tooltip>
                  </Box>
                </H6>
                {authState?.features &&
                  authState?.features?.includes("bekeu_pro") && product?.service_fee > 0.0 && (
                    <Text>
                      <strong>Tarifa de servicio: </strong>
                      {product?.currency_code === "ARS"
                        ? ARS.format(product?.service_fee)
                        : USD.format(product.service_fee)}{" "}
                      + Imp
                    </Text>
                  )}
              </Flex>
              <Button
                variant={"ghost"}
                borderRadius={"0.75rem"}
                m={0}
                mr={2}
                p={0}
                _focus={{
                  boxShadow:
                    "0 0 0.0625rem 0.125rem rgba(122, 57, 162, 1), 0 0.0625rem 0.0625rem rgba(122, 57, 162, 1)",
                }}
                onClick={() => {
                  setLoading(true);
                  handleRemoveCartItem(product);
                }}
                isLoading={loading}
              >
                <BekeuIcon src={trash} />
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
