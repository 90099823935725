import axios from "axios";
import { urlsBase } from "../../../../config";

export const STORE_CREDENTIALS_SAP = `${urlsBase.base_url}/api/v1/companies/store-credentials-sap`;
export const GET_CREDENTIALS_SAP = `${urlsBase.base_url}/api/v1/companies/get-credentials-sap`;
export const TEST_CREDENTIALS_SAP = `${urlsBase.base_url}/api/v1/companies/test-credentials-sap`;

export function storeCredentialsSAP(payload) {
  return axios.post(`${STORE_CREDENTIALS_SAP}`, payload);
}

export function getCredentialsSAP() {
  return axios.get(`${GET_CREDENTIALS_SAP}`);
}

export function testCredentialsSAP(payload) {
  return axios.post(`${TEST_CREDENTIALS_SAP}`, payload);
}
