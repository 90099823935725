import React from 'react';
import {
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Flex,
  Img,
  Box,
  useToast,
  ListItem,
  Divider,
  UnorderedList,
  ModalHeader,
} from "@chakra-ui/react";
import smsTracking from "../../design-system/icons/Contact/sms-tracking.svg";
import { H5 } from "../../design-system/headings/H5";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { connect, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import { actions as cartActions } from "../../store/cart/cart.duck";
import { useEffect } from "react";
import { useState } from "react";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { useHistory } from "react-router-dom";
import errorsvg from "../../design-system/icons/Errors/error-sap.svg";
import successsvg from "../../design-system/icons/Arrows/success-circle-fill.svg";

import useFeatures from '../../hooks/useFeatures';

const CartSuccessModal = ({
  isDisabled,
  handleEndsBuy,
  cart,
  getCart,
  address,
  ...props
}) => {
  const toast = useToast();
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();

  // const [orderData, setOrderData] = useState({
  //   cartId: cart?.id,
  //   totalARS: cart?.total_ARP,
  //   totalUSD: cart?.total_USD,
  //   internalCode: cart?.internal_code,
  //   shipping_cost_ARS: cart?.shipping_cost_ARP,
  //   shipping_cost_USD: cart?.shipping_cost_USD,
  //   total_w_shipping_ARS: cart?.total_w_shipping_ARP,
  //   total_w_shipping_USD: cart?.total_w_shipping_USD,
  // });

  const cartState = useSelector((state) => state.cart);
  const [loading, setLoading] = useState(false);

  const { imputationUser, companyApprovalsUser, sapUser } = useFeatures();

  const handleClickButton = () => {
    handleEndsBuy();
  };

  // useEffect(() => {
  //   cart?.id &&
  //     setOrderData({
  //       cartId: cart?.id,
  //       totalARS: cart?.total_ARP,
  //       totalUSD: cart?.total_USD,
  //       internalCode: cart?.internal_code,
  //       shipping_cost_ARS: cart?.shipping_cost_ARP,
  //       shipping_cost_USD: cart?.shipping_cost_USD,
  //       total_w_shipping_ARS: cart?.total_w_shipping_ARP,
  //       total_w_shipping_USD: cart?.total_w_shipping_USD,
  //     });
  // }, [cart]);

  useEffect(() => {
    setLoading(cartState?.loading || false);
  }, [cartState?.loading])

  useEffect(() => {
    if (
      cartState?.endsBuyResultValue &&
      Object.values(cartState?.endsBuyResultValue).length > 0
    ) {
      return onOpen();
    }

  }, [cartState.endsBuyResultValue]);

  function handleScrollTo() {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  const arrayProduct = [];

  const handleErrorEndBuy = () => {
    handleScrollTo();
    cart.items.map(item => arrayProduct.push(item?.error));
    if (arrayProduct.includes(true)) {
      toast({
        title: 'Error al finalizar compra',
        description:
          'No se puede generar orden de compra con productos fuera de stock',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    } else {
      handleClickButton();
    }
  };

  return (
    <>
      {imputationUser ? (
        <ButtonBekeu
          onClick={handleErrorEndBuy}
          isDisabled={isDisabled}
          isLoading={loading && !cartState?.endsBuyResult}
        >
          Finalizar compra
        </ButtonBekeu>
      ) : (
        <ButtonBekeu
          onClick={handleErrorEndBuy}
          isDisabled={!address}
          isLoading={loading && !cartState?.endsBuyResult}
        >
          Finalizar compra
        </ButtonBekeu>
      )}

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        onCloseComplete={() => {
          cartState?.endsBuyResultValue?.status !== 'fail' &&
            props.fillCart([]);
          props?.endsBuyResultValue({});
          history.push('/');
        }}
        size={'xl'}
        isCentered
      >
        <ModalOverlay />
        <ModalContent borderRadius={'0.9375rem'} minW="33rem">
          <ModalHeader paddingY={7}>
            <ModalCloseButton _focus={{ boxShadow: 'rgba(0,0,0,0)' }} />
          </ModalHeader>
          <ModalBody borderRadius={'0.9375rem'} p={'3rem'}>
            <Flex
              flexDir={'column'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              {/* Success */}
              {!cartState?.endsBuyResultValue?.status?.error &&
              cartState?.endsBuyResultValue?.status !== 'fail' ? (
                <>
                  {!companyApprovalsUser ? (
                    <Flex
                      flexDir={'column'}
                      justifyContent="center"
                      alignItems="center"
                      gap={6}
                      mb={6}
                    >
                      <Img src={successsvg} />
                      <H5 textAlign="center">
                        ¡Tu compra fue realizada con éxito!
                      </H5>

                    {(!sapUser) && (
                      <Paragraphs
                        fontWeight={600}
                        fontSize={'1rem'}
                        lineHeight="1.3619rem"
                        color={'brand.success'}
                      >
                        Carrito Nº {cartState?.items?.internal_code}
                      </Paragraphs>
                    )}
                    </Flex>
                  ) : (
                    <>
                      <Flex alignItems={'center'} my={6}>
                        <Img src={smsTracking} alt="smsTracking" />
                      </Flex>
                      <H5 mb={4} textAlign={'center'}>
                        ¡Tu compra fue enviada a aprobación!
                      </H5>
                      {cartState?.endsBuyResultValue?.message?.data
                        ?.approver && (
                        <Box
                          color={'brand.primary'}
                          bg={'rgba(100, 36, 126, 0.1)'}
                          px={2}
                          borderRadius={'0.75rem'}
                          w={'fit-content'}
                          maxW={'22rem'}
                          mb={4}
                          textAlign={'center'}
                          lineHeight="1.3619rem"
                        >
                          {`Aprobador: ${cartState?.endsBuyResultValue?.message?.data?.approver}`}
                        </Box>
                      )}
                        <Paragraphs
                          fontWeight={600}
                          fontSize={'1rem'}
                          color={'brand.success'}
                          mt={4}
                          lineHeight="1.3619rem"
                          alignSelf={"unset"}
                        >
                          Carrito N° {cartState?.items?.internal_code}
                        </Paragraphs>
                    </>
                  )}
                </>
              ) : ( 
                // Error
                <>
                  <Flex alignItems={"center"} mb={6}>
                    <Img src={errorsvg} alt="error" />
                  </Flex>
                  <H5 mb={4}>No se creó la compra</H5>
                </>
              )}
            </Flex>

            {sapUser && (
              <>                
                <Flex
                  flexDir={'column'}
                  justifyContent="center"
                  alignItems="center"
                  gap="1rem"
                  mb={6}
                >

                  {cartState?.endsBuyResultValue &&
                    Object.entries(cartState?.endsBuyResultValue).length > 0 &&
                    cartState?.endsBuyResultValue?.message?.success !== null &&
                      <>
                    
                        
                        {cartState?.endsBuyResultValue?.message?.success.map(
                          (successItem) =>
                            Object.keys(successItem).map((success) => {
                              const sapMsg =
                                Array.isArray(successItem[success]) &&
                                successItem[success].length > 0
                                  ? successItem[success][0]
                                  : success;
                              const sapNumber = sapMsg?.replace(
                                /^\D+/g,
                                "",
                              );
                              return (
                                <Flex
                                  key={Math.random()}
                                  flexDir={"column"}
                                >
                                  <Paragraphs
                                    fontWeight={600}
                                    fontSize={"1.125rem"}
                                    color={'brand.success'}
                                  >
                                    Carrito Nº {cartState?.items?.internal_code}
                                  </Paragraphs>
                                  <Paragraphs
                                    fontWeight={600}
                                    fontSize={"1.125rem"}
                                    color="brand.success"
                                  >
                                    Compra Nº {success}
                                  </Paragraphs>
                                  <Paragraphs lineHeight="1.50rem">
                                    {sapNumber?.length > 0 &&
                                      `Pedido Bekeu creado bajo el número ${sapNumber}`}
                                  </Paragraphs>
                                </Flex>
                              );
                            }),
                          )}
                      </>  
                  }

                  {cartState?.endsBuyResultValue?.status === 'fail' && (
                    <>
                     
                      {cartState?.endsBuyResultValue &&
                        Object.entries(cartState?.endsBuyResultValue).length > 0 &&
                        cartState?.endsBuyResultValue?.error?.error !== null &&
                        cartState?.endsBuyResultValue?.error?.error.map((errorsItem) =>
                          Object.keys(errorsItem).map((error) => {
                            const sapMsg =
                              Array.isArray(errorsItem[error]) &&
                              errorsItem[error].length > 0
                                ? errorsItem[error][0]
                                : error;
                            return (
                              <Flex flexDir={"column"}>
                                <Paragraphs
                                  fontWeight={600}
                                  fontSize={"1.125rem"}
                                  color="brand.error"
                                >
                                  Compra Nº {error} <br />
                                </Paragraphs>
                                <br />
                                <Paragraphs lineHeight="1.50rem">
                                  La compra no pudo realizarse por los
                                  siguientes motivos:
                                </Paragraphs>
                                <br />
                                <Paragraphs lineHeight="1.50rem">
                                  <UnorderedList>
                                    {Array.isArray(
                                      errorsItem[error],
                                    ) &&
                                    errorsItem[error].length > 0 ? (
                                      errorsItem[error].map(
                                        (item, id) => (
                                          <ListItem key={id}>
                                            {item}
                                          </ListItem>
                                        ),
                                      )
                                    ) : (
                                      <ListItem>{sapMsg}</ListItem>
                                    )}
                                  </UnorderedList>
                                </Paragraphs>
                              </Flex>
                            );
                          }),
                        )}
                    </>
                  )}
                </Flex>

              </>
            )}

          

            <Flex gap="1.5rem" mt={4}>
              <ButtonBekeuOutline
                onClick={() => {
                  cartState?.endsBuyResultValue?.status !== 'fail' &&
                    props.fillCart([]);
                  props?.endsBuyResultValue({});
                  history.push('/');
                }}
              >
                Volver al home
              </ButtonBekeuOutline>
              <ButtonBekeu
                my={0}
                onClick={() => {
                  cartState?.endsBuyResultValue?.status !== 'fail' &&
                    props.fillCart([]);
                  props?.endsBuyResultValue({});
                  history.push('/my-orders/?page=1&per_page=20');
                }}
              >
                Ir a mis compras
              </ButtonBekeu>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default injectIntl(connect(null, cartActions)(CartSuccessModal));
