import React, { useEffect, useState, useRef } from "react";
import { connect, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { injectIntl } from "react-intl";

import { Progress, Grid, GridItem, useToast } from "@chakra-ui/react";

import SalesItem from "../../components/SalesItem/SalesItem";

import { H5 } from "../../design-system/headings/H5";
import { AlertBekeu } from "../../design-system/overlay/AlertBekeu";

import useQuery from "../../hooks/useQuery";

import * as product from "../../pages/productGridPage/ducks/productsGrid.duck";
import * as sales from "../salesPage/ducks/sales.duck";

const SalesDetailPage = ({
  clearListDetailSales,
  listDetailSales,
  approveSales,
  rejectSales,
  ...props
}) => {
  const params = useParams();
  const salesState = useSelector((state) => state?.sales);
  const [remitNumber, setRemitNumber] = useState("");
  const [sellerComments, setSellerComments] = useState("");
  const [fileNameInput, setFileNameInput] = useState({ name: "", file: null });
  const [msgFileNameInput, setMsgFileNameInput] = useState("");
  const [itemsWCollapseId, setItemsWCollapseId] = useState([]);
  const [filteredItem, setFilteredItem] = useState([]);
  const [historyToCollapse, setHistoryToCollapse] = useState([]);

  const query = useQuery();
  const state = query.get("state");
  const tenant = query.get("tenant") ?? "tenant";

  const toast = useToast();

  useEffect(() => {
    clearListDetailSales();

    setMsgFileNameInput(() =>
      setMsgFileNameInput(
        "Por favor, subí el archivo del remito conformado en formato PNG, JPG o PDF y con tamaño máximo de 2 MB",
      ),
    );
  }, []);

  useEffect(() => {
    listDetailSales({
      order_id: params.orderId,
      tenant: tenant,
    });
  }, [params.orderId]);

  useEffect(() => {
    setFilteredItem(salesState.sales);
  }, [salesState]);

  useEffect(() => {
    if (salesState?.hasError && salesState?.hasError.status === "fail") {
      toast({
        title: salesState?.hasError?.error,
        position: "top-right",
        status: "error",
        duration: undefined,
        isClosable: true,
      });
    }
  }, [salesState.hasError]);

  useEffect(() => {
    if (filteredItem.length > 0) {
      let indexCollapse = 0;
      const newItems = [...filteredItem].map((it) => ({
        ...it,
        buy_order_items: it.buy_order_items?.map((boi) => ({
          ...boi,
          collapseIndex: indexCollapse++,
        })),
      }));

      setItemsWCollapseId(newItems);
    }
  }, [filteredItem]);

  const handleChangeFileNameInput = (value) => {
    setFileNameInput(value);
  };

  const handleSetHistoryToCollapse = (history) => {
    setHistoryToCollapse(history);
  };

  const handleApproveOrder = (item, itemsState) => {
    approveSales({
      item,
      items: itemsState.filter((item) => item.qty_sent > 0),
      dispatch_note_number: remitNumber,
      tenant: tenant,
      file: fileNameInput.file,
    });
  };

  const handleRejectOrder = (item, itemsState, payload) => {
    const deliveryTime = itemsState.map(
      (oItem) => { return oItem.delivery_time }
    )
    rejectSales({
      item,
      items: itemsState,
      delivery_time: deliveryTime[0],
      tenant: tenant,
      outdatedPrice: payload?.outdatedPrice,
      discontinuedProduct: payload?.discontinuedProduct,
      outOfStock: payload?.outOfStock,
      other: payload?.other,
      otherReasonsReject: payload?.otherReasonsNormalized,
    });
  };

  const handlePagination = () => {
    listDetailSales({ order_id: params.orderId, tenant: tenant });
  };

  return (
    <Grid
      flexDir={"column"}
      py={"2.5rem"}
      bg={"brand.background"}
      maxW={"75rem"}
      w={"100%"}
      margin={"auto"}
      px={["1rem", "1rem", "1rem", ".5rem"]}
    /*{...props}*/
    >
      {!salesState.loading &&
        itemsWCollapseId &&
        itemsWCollapseId.length > 0 ? (
        <>
          <Grid
            alignItems={"center"}
            templateColumns={["unset", "unset", "unset", "20% auto"]}
          >
            <GridItem>
              <H5>Detalle del pedido</H5>
            </GridItem>
            <GridItem justifySelf={["center", "flex-end"]}>
              <AlertBekeu my={"1.5rem"}>
                Si tus productos tienen plazos de entregas distintos ahora podés realizar entregas parciales y evitar demoras.
              </AlertBekeu>
            </GridItem>
          </Grid>
          {
            itemsWCollapseId.map((item, id, itemArr) => {
              return (
                <SalesItem
                  mt={"2rem"}
                  type={item.state}
                  key={id}
                  item={item}
                  itemArr={itemArr}
                  showitems
                  selected
                  status={item.status === "pending" ? true : undefined}
                  handleApproveOrder={handleApproveOrder}
                  handleRejectOrder={handleRejectOrder}
                  setRemitNumber={setRemitNumber}
                  setSellerComments={setSellerComments}
                  handlePagination={handlePagination}
                  sellerComments={sellerComments}
                  remitNumber={remitNumber}
                  params={params}
                  state={state}
                  getHistory={props.getHistory}
                  tenant={tenant}
                  msgFileNameInput={msgFileNameInput}
                  fileNameInput={fileNameInput}
                  handleChangeFileNameInput={handleChangeFileNameInput}
                  handleSetHistoryToCollapse={handleSetHistoryToCollapse}
                  historyToCollapse={historyToCollapse}
                />
              );
            })
          }
        </>
      ) : (
        <GridItem>
          <Progress
            size="xs"
            isIndeterminate
            colorScheme={"purple"}
            mt={"10"}
          />
        </GridItem>
      )}
    </Grid>
  );
};

export default injectIntl(
  connect(null, { ...sales.actions, ...product.actions })(SalesDetailPage),
);
